import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from "sweetalert2";


const Header = () => {
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    console.log("Hello ji ")
    setMenuOpen(!menuOpen);
  };
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!",
      cancelButtonText: "No, stay logged in",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token");
        navigate("/");
        Swal.fire(
          "Logged Out!",
          "You have been logged out successfully.",
          "success"
        );
      }
    });
  };


  return (
   <>

   <div
              id="kt_header"
              style={{}}
              className="header align-items-stretch"
            >
              {/*begin::Container*/}
              <div className="container-fluid d-flex align-items-stretch justify-content-between">
                {/*begin::Aside mobile toggle*/}
                <div
                  className="d-flex align-items-center d-lg-none ms-n4 me-1"
                  title="Show aside menu"
                >
                  <div
                    className="btn btn-icon btn-active-color-white"
                    id="kt_aside_mobile_toggle"
                  >
                    <i className="ki-outline ki-burger-menu fs-1" />
                  </div>
                </div>
                {/*end::Aside mobile toggle*/}
                {/*begin::Mobile logo*/}
                <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                  <a href="index.html" className="d-lg-none">
                    <img
                      alt="Logo"
                      src="assets/media/logos/demo13-small.svg"
                      className="h-25px"
                    />
                  </a>
                </div>
                {/*end::Mobile logo*/}
                <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                  {/*begin::Navbar*/}
                  <div
                    className="d-flex align-items-stretch"
                    id="kt_header_nav"
                  >
                    {/*begin::Menu wrapper*/}
                    <div
                      className="header-menu align-items-stretch"
                      data-kt-drawer="true"
                      data-kt-drawer-name="header-menu"
                      data-kt-drawer-activate="{default: true, lg: false}"
                      data-kt-drawer-overlay="true"
                      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                      data-kt-drawer-direction="end"
                      data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
                      data-kt-swapper="true"
                      data-kt-swapper-mode="prepend"
                      data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
                    >
                      <div
                        className="menu menu-rounded menu-column menu-lg-row menu-root-here-bg-desktop menu-active-bg menu-state-primary menu-title-gray-800 menu-arrow-gray-500 align-items-stretch my-5 my-lg-0 px-2 px-lg-0 fw-semibold fs-6"
                        id="#kt_header_menu"
                        data-kt-menu="true"
                      >
                        {/*begin:Menu item*/}
                        <div
                          data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                          data-kt-menu-placement="bottom-start"
                          className="menu-item here show menu-here-bg menu-lg-down-accordion me-0 me-lg-2"
                        >
                          {/*begin:Menu link*/}
                          <span className="menu-link py-3">
                            <Link to="/dashboardpage" className="menu-title">Dashboards</Link>
                            <span className="menu-arrow d-lg-none" />
                          </span>

                        </div>




                        {/*end:Menu item*/}
                      </div>
                    </div>
                  </div>

                  <div className="topbar d-flex align-items-stretch flex-shrink-0">
                    {/*begin::Search*/}
                    <div className="d-flex align-items-stretch">
                      {/*begin::Search*/}
                      <div
                        id="kt_header_search"
                        className="header-search d-flex align-items-stretch"
                        data-kt-search-keypress="true"
                        data-kt-search-min-length={2}
                        data-kt-search-enter="enter"
                        data-kt-search-layout="menu"
                        data-kt-menu-trigger="auto"
                        data-kt-menu-overflow="false"
                        data-kt-menu-permanent="true"
                        data-kt-menu-placement="bottom-end"
                      >
                        {/*begin::Search toggle*/}
                        <div
                          className="d-flex align-items-center"
                          data-kt-search-element="toggle"
                          id="kt_header_search_toggle"
                        >
                          <div className="topbar-item px-3 px-lg-4">
                            <i className="ki-outline ki-magnifier fs-1" />
                          </div>
                        </div>
                        {/*end::Search toggle*/}
                        <div
                          data-kt-search-element="content"
                          className="menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px"
                        >
                          <div data-kt-search-element="wrapper">
                            {/*begin::Form*/}
                            <form
                              data-kt-search-element="form"
                              className="w-100 position-relative mb-3"
                              autoComplete="off"
                            >
                              {/*begin::Icon*/}
                              <i className="ki-outline ki-magnifier fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-0" />
                              {/*end::Icon*/}
                              {/*begin::Input*/}
                              <input
                                type="text"
                                className="search-input form-control form-control-flush ps-10"
                                name="search"
                                defaultValue=""
                                placeholder="Search..."
                                data-kt-search-element="input"
                              />
                              {/*end::Input*/}
                              {/*begin::Spinner*/}
                              <span
                                className="search-spinner position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1"
                                data-kt-search-element="spinner"
                              >
                                <span className="spinner-border h-15px w-15px align-middle text-gray-500" />
                              </span>
                              {/*end::Spinner*/}
                              {/*begin::Reset*/}
                              <span
                                className="search-reset btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none"
                                data-kt-search-element="clear"
                              >
                                <i className="ki-outline ki-cross fs-2 fs-lg-1 me-0" />
                              </span>
                              {/*end::Reset*/}
                              {/*begin::Toolbar*/}
                              <div
                                className="position-absolute top-50 end-0 translate-middle-y"
                                data-kt-search-element="toolbar"
                              >
                                {/*begin::Preferences toggle*/}
                                <div
                                  data-kt-search-element="preferences-show"
                                  className="btn btn-icon w-20px btn-sm btn-active-color-primary me-1"
                                  data-bs-toggle="tooltip"
                                  title="Show search preferences"
                                >
                                  <i className="ki-outline ki-setting-2 fs-2" />
                                </div>
                                {/*end::Preferences toggle*/}
                                {/*begin::Advanced search toggle*/}
                                <div
                                  data-kt-search-element="advanced-options-form-show"
                                  className="btn btn-icon w-20px btn-sm btn-active-color-primary"
                                  data-bs-toggle="tooltip"
                                  title="Show more search options"
                                >
                                  <i className="ki-outline ki-down fs-2" />
                                </div>
                                {/*end::Advanced search toggle*/}
                              </div>
                              {/*end::Toolbar*/}
                            </form>
                            <div className="separator border-gray-200 mb-6" />
                            {/*begin::Recently viewed*/}
                            <div
                              data-kt-search-element="results"
                              className="d-none"
                            >
                              {/*begin::Items*/}
                              <div className="scroll-y mh-200px mh-lg-350px">
                                {/*begin::Category title*/}
                                <h3
                                  className="fs-5 text-muted m-0 pb-5"
                                  data-kt-search-element="category-title"
                                >
                                  Users
                                </h3>
                                {/*end::Category title*/}
                                {/*begin::Item*/}
                                <a
                                  href="/"
                                  className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                                >
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-40px me-4">
                                    <img
                                      src="assets/media/avatars/300-6.jpg"
                                      alt=""
                                    />
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="d-flex flex-column justify-content-start fw-semibold">
                                    <span className="fs-6 fw-semibold">
                                      Karina Clark
                                    </span>
                                    <span className="fs-7 fw-semibold text-muted">
                                      Marketing Manager
                                    </span>
                                  </div>
                                </a>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <a
                                  href="/"
                                  className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                                >
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-40px me-4">
                                    <img
                                      src="assets/media/avatars/300-2.jpg"
                                      alt=""
                                    />
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="d-flex flex-column justify-content-start fw-semibold">
                                    <span className="fs-6 fw-semibold">
                                      Olivia Bold
                                    </span>
                                    <span className="fs-7 fw-semibold text-muted">
                                      Software Engineer
                                    </span>
                                  </div>
                                </a>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <a
                                  href="/"
                                  className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                                >
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-40px me-4">
                                    <img
                                      src="assets/media/avatars/300-9.jpg"
                                      alt=""
                                    />
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="d-flex flex-column justify-content-start fw-semibold">
                                    <span className="fs-6 fw-semibold">
                                      Ana Clark
                                    </span>
                                    <span className="fs-7 fw-semibold text-muted">
                                      UI/UX Designer
                                    </span>
                                  </div>
                                </a>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <a
                                  href="/"
                                  className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                                >
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-40px me-4">
                                    <img
                                      src="assets/media/avatars/300-14.jpg"
                                      alt=""
                                    />
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="d-flex flex-column justify-content-start fw-semibold">
                                    <span className="fs-6 fw-semibold">
                                      Nick Pitola
                                    </span>
                                    <span className="fs-7 fw-semibold text-muted">
                                      Art Director
                                    </span>
                                  </div>
                                </a>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <a
                                  href="/"
                                  className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                                >
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-40px me-4">
                                    <img
                                      src="assets/media/avatars/300-11.jpg"
                                      alt=""
                                    />
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="d-flex flex-column justify-content-start fw-semibold">
                                    <span className="fs-6 fw-semibold">
                                      Edward Kulnic
                                    </span>
                                    <span className="fs-7 fw-semibold text-muted">
                                      System Administrator
                                    </span>
                                  </div>
                                </a>
                                {/*end::Item*/}
                                {/*begin::Category title*/}
                                <h3
                                  className="fs-5 text-muted m-0 pt-5 pb-5"
                                  data-kt-search-element="category-title"
                                >
                                  Customers
                                </h3>
                                {/*end::Category title*/}
                                {/*begin::Item*/}
                                <a
                                  href="/"
                                  className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                                >
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-40px me-4">
                                    <span className="symbol-label bg-light">
                                      <img
                                        className="w-20px h-20px"
                                        src="assets/media/svg/brand-logos/volicity-9.svg"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="d-flex flex-column justify-content-start fw-semibold">
                                    <span className="fs-6 fw-semibold">
                                      Company Rbranding
                                    </span>
                                    <span className="fs-7 fw-semibold text-muted">
                                      UI Design
                                    </span>
                                  </div>
                                </a>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <a
                                  href="/"
                                  className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                                >
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-40px me-4">
                                    <span className="symbol-label bg-light">
                                      <img
                                        className="w-20px h-20px"
                                        src="assets/media/svg/brand-logos/tvit.svg"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="d-flex flex-column justify-content-start fw-semibold">
                                    <span className="fs-6 fw-semibold">
                                      Company Re-branding
                                    </span>
                                    <span className="fs-7 fw-semibold text-muted">
                                      Web Development
                                    </span>
                                  </div>
                                </a>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <a
                                  href="/"
                                  className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                                >
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-40px me-4">
                                    <span className="symbol-label bg-light">
                                      <img
                                        className="w-20px h-20px"
                                        src="assets/media/svg/misc/infography.svg"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="d-flex flex-column justify-content-start fw-semibold">
                                    <span className="fs-6 fw-semibold">
                                      Business Analytics App
                                    </span>
                                    <span className="fs-7 fw-semibold text-muted">
                                      Administration
                                    </span>
                                  </div>
                                </a>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <a
                                  href="/"
                                  className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                                >
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-40px me-4">
                                    <span className="symbol-label bg-light">
                                      <img
                                        className="w-20px h-20px"
                                        src="assets/media/svg/brand-logos/leaf.svg"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="d-flex flex-column justify-content-start fw-semibold">
                                    <span className="fs-6 fw-semibold">
                                      EcoLeaf App Launch
                                    </span>
                                    <span className="fs-7 fw-semibold text-muted">
                                      Marketing
                                    </span>
                                  </div>
                                </a>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <a
                                  href="/"
                                  className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                                >
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-40px me-4">
                                    <span className="symbol-label bg-light">
                                      <img
                                        className="w-20px h-20px"
                                        src="assets/media/svg/brand-logos/tower.svg"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="d-flex flex-column justify-content-start fw-semibold">
                                    <span className="fs-6 fw-semibold">
                                      Tower Group Website
                                    </span>
                                    <span className="fs-7 fw-semibold text-muted">
                                      Google Adwords
                                    </span>
                                  </div>
                                </a>
                                {/*end::Item*/}
                                {/*begin::Category title*/}
                                <h3
                                  className="fs-5 text-muted m-0 pt-5 pb-5"
                                  data-kt-search-element="category-title"
                                >
                                  Projects
                                </h3>
                                {/*end::Category title*/}
                                {/*begin::Item*/}
                                <a
                                  href="/"
                                  className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                                >
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-40px me-4">
                                    <span className="symbol-label bg-light">
                                      <i className="ki-outline ki-notepad fs-2 text-primary" />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="d-flex flex-column">
                                    <span className="fs-6 fw-semibold">
                                      Si-Fi Project by AU Themes
                                    </span>
                                    <span className="fs-7 fw-semibold text-muted">
                                      #45670
                                    </span>
                                  </div>
                                </a>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <a
                                  href="/"
                                  className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                                >
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-40px me-4">
                                    <span className="symbol-label bg-light">
                                      <i className="ki-outline ki-frame fs-2 text-primary" />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="d-flex flex-column">
                                    <span className="fs-6 fw-semibold">
                                      Shopix Mobile App Planning
                                    </span>
                                    <span className="fs-7 fw-semibold text-muted">
                                      #45690
                                    </span>
                                  </div>
                                </a>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <a
                                  href="/"
                                  className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                                >
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-40px me-4">
                                    <span className="symbol-label bg-light">
                                      <i className="ki-outline ki-message-text-2 fs-2 text-primary" />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="d-flex flex-column">
                                    <span className="fs-6 fw-semibold">
                                      Finance Monitoring SAAS Discussion
                                    </span>
                                    <span className="fs-7 fw-semibold text-muted">
                                      #21090
                                    </span>
                                  </div>
                                </a>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <a
                                  href="/"
                                  className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                                >
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-40px me-4">
                                    <span className="symbol-label bg-light">
                                      <i className="ki-outline ki-profile-circle fs-2 text-primary" />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="d-flex flex-column">
                                    <span className="fs-6 fw-semibold">
                                      Dashboard Analitics Launch
                                    </span>
                                    <span className="fs-7 fw-semibold text-muted">
                                      #34560
                                    </span>
                                  </div>
                                </a>
                                {/*end::Item*/}
                              </div>
                              {/*end::Items*/}
                            </div>
                            {/*end::Recently viewed*/}
                            {/*begin::Recently viewed*/}
                            <div className="mb-5" data-kt-search-element="main">
                              {/*begin::Heading*/}
                              <div className="d-flex flex-stack fw-semibold mb-4">
                                {/*begin::Label*/}
                                <span className="text-muted fs-6 me-2">
                                  Recently Searched:
                                </span>
                                {/*end::Label*/}
                              </div>
                              {/*end::Heading*/}
                              {/*begin::Items*/}
                              <div className="scroll-y mh-200px mh-lg-325px">
                                {/*begin::Item*/}
                                <div className="d-flex align-items-center mb-5">
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-40px me-4">
                                    <span className="symbol-label bg-light">
                                      <i className="ki-outline ki-laptop fs-2 text-primary" />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="d-flex flex-column">
                                    <a
                                      href="/"
                                      className="fs-6 text-gray-800 text-hover-primary fw-semibold"
                                    >
                                      BoomApp by Keenthemes
                                    </a>
                                    <span className="fs-7 text-muted fw-semibold">
                                      #45789
                                    </span>
                                  </div>
                                </div>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <div className="d-flex align-items-center mb-5">
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-40px me-4">
                                    <span className="symbol-label bg-light">
                                      <i className="ki-outline ki-chart-simple fs-2 text-primary" />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="d-flex flex-column">
                                    <a
                                      href="/"
                                      className="fs-6 text-gray-800 text-hover-primary fw-semibold"
                                    >
                                      "Kept API Project Meeting
                                    </a>
                                    <span className="fs-7 text-muted fw-semibold">
                                      #84050
                                    </span>
                                  </div>
                                </div>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <div className="d-flex align-items-center mb-5">
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-40px me-4">
                                    <span className="symbol-label bg-light">
                                      <i className="ki-outline ki-chart fs-2 text-primary" />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="d-flex flex-column">
                                    <a
                                      href="/"
                                      className="fs-6 text-gray-800 text-hover-primary fw-semibold"
                                    >
                                      "KPI Monitoring App Launch
                                    </a>
                                    <span className="fs-7 text-muted fw-semibold">
                                      #84250
                                    </span>
                                  </div>
                                </div>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <div className="d-flex align-items-center mb-5">
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-40px me-4">
                                    <span className="symbol-label bg-light">
                                      <i className="ki-outline ki-chart-line-down fs-2 text-primary" />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="d-flex flex-column">
                                    <a
                                      href="/"
                                      className="fs-6 text-gray-800 text-hover-primary fw-semibold"
                                    >
                                      Project Reference FAQ
                                    </a>
                                    <span className="fs-7 text-muted fw-semibold">
                                      #67945
                                    </span>
                                  </div>
                                </div>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <div className="d-flex align-items-center mb-5">
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-40px me-4">
                                    <span className="symbol-label bg-light">
                                      <i className="ki-outline ki-sms fs-2 text-primary" />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="d-flex flex-column">
                                    <a
                                      href="/"
                                      className="fs-6 text-gray-800 text-hover-primary fw-semibold"
                                    >
                                      "FitPro App Development
                                    </a>
                                    <span className="fs-7 text-muted fw-semibold">
                                      #84250
                                    </span>
                                  </div>
                                </div>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <div className="d-flex align-items-center mb-5">
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-40px me-4">
                                    <span className="symbol-label bg-light">
                                      <i className="ki-outline ki-bank fs-2 text-primary" />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="d-flex flex-column">
                                    <a
                                      href="/"
                                      className="fs-6 text-gray-800 text-hover-primary fw-semibold"
                                    >
                                      Shopix Mobile App
                                    </a>
                                    <span className="fs-7 text-muted fw-semibold">
                                      #45690
                                    </span>
                                  </div>
                                </div>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <div className="d-flex align-items-center mb-5">
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-40px me-4">
                                    <span className="symbol-label bg-light">
                                      <i className="ki-outline ki-chart-line-down fs-2 text-primary" />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="d-flex flex-column">
                                    <a
                                      href="/"
                                      className="fs-6 text-gray-800 text-hover-primary fw-semibold"
                                    >
                                      "Landing UI Design" Launch
                                    </a>
                                    <span className="fs-7 text-muted fw-semibold">
                                      #24005
                                    </span>
                                  </div>
                                </div>
                                {/*end::Item*/}
                              </div>
                              {/*end::Items*/}
                            </div>
                            {/*end::Recently viewed*/}
                            {/*begin::Empty*/}
                            <div
                              data-kt-search-element="empty"
                              className="text-center d-none"
                            >
                              {/*begin::Icon*/}
                              <div className="pt-10 pb-10">
                                <i className="ki-outline ki-search-list fs-4x opacity-50" />
                              </div>
                              {/*end::Icon*/}
                              {/*begin::Message*/}
                              <div className="pb-15 fw-semibold">
                                <h3 className="text-gray-600 fs-5 mb-2">
                                  No result found
                                </h3>
                                <div className="text-muted fs-7">
                                  Please try again with a different query
                                </div>
                              </div>
                              {/*end::Message*/}
                            </div>
                            {/*end::Empty*/}
                          </div>
                          {/*begin::Preferences*/}
                          <form
                            data-kt-search-element="advanced-options-form"
                            className="pt-1 d-none"
                          >
                            {/*begin::Heading*/}
                            <h3 className="fw-semibold text-gray-900 mb-7">
                              Advanced Search
                            </h3>
                            {/*end::Heading*/}
                            {/*begin::Input group*/}
                            <div className="mb-5">
                              <input
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                placeholder="Contains the word"
                                name="query"
                              />
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className="mb-5">
                              {/*begin::Radio group*/}
                              <div className="nav-group nav-group-fluid">
                                {/*begin::Option*/}
                                <label>
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="type"
                                    defaultValue="has"
                                    defaultChecked="checked"
                                  />
                                  <span className="btn btn-sm btn-color-muted btn-active btn-active-primary">
                                    All
                                  </span>
                                </label>
                                {/*end::Option*/}
                                {/*begin::Option*/}
                                <label>
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="type"
                                    defaultValue="users"
                                  />
                                  <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
                                    Users
                                  </span>
                                </label>
                                {/*end::Option*/}
                                {/*begin::Option*/}
                                <label>
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="type"
                                    defaultValue="orders"
                                  />
                                  <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
                                    Orders
                                  </span>
                                </label>
                                {/*end::Option*/}
                                {/*begin::Option*/}
                                <label>
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="type"
                                    defaultValue="projects"
                                  />
                                  <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
                                    Projects
                                  </span>
                                </label>
                                {/*end::Option*/}
                              </div>
                              {/*end::Radio group*/}
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className="mb-5">
                              <input
                                type="text"
                                name="assignedto"
                                className="form-control form-control-sm form-control-solid"
                                placeholder="Assigned to"
                                defaultValue=""
                              />
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className="mb-5">
                              <input
                                type="text"
                                name="collaborators"
                                className="form-control form-control-sm form-control-solid"
                                placeholder="Collaborators"
                                defaultValue=""
                              />
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className="mb-5">
                              {/*begin::Radio group*/}
                              <div className="nav-group nav-group-fluid">
                                {/*begin::Option*/}
                                <label>
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="attachment"
                                    defaultValue="has"
                                    defaultChecked="checked"
                                  />
                                  <span className="btn btn-sm btn-color-muted btn-active btn-active-primary">
                                    Has attachment
                                  </span>
                                </label>
                                {/*end::Option*/}
                                {/*begin::Option*/}
                                <label>
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="attachment"
                                    defaultValue="any"
                                  />
                                  <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
                                    Any
                                  </span>
                                </label>
                                {/*end::Option*/}
                              </div>
                              {/*end::Radio group*/}
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className="mb-5">
                              <select
                                name="timezone"
                                aria-label="Select a Timezone"
                                data-control="select2"
                                data-dropdown-parent="#kt_header_search"
                                data-placeholder="date_period"
                                className="form-select form-select-sm form-select-solid"
                              >
                                <option value="next">Within the next</option>
                                <option value="last">Within the last</option>
                                <option value="between">Between</option>
                                <option value="on">On</option>
                              </select>
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className="row mb-8">
                              {/*begin::Col*/}
                              <div className="col-6">
                                <input
                                  type="number"
                                  name="date_number"
                                  className="form-control form-control-sm form-control-solid"
                                  placeholder="Lenght"
                                  defaultValue=""
                                />
                              </div>
                              {/*end::Col*/}
                              {/*begin::Col*/}
                              <div className="col-6">
                                <select
                                  name="date_typer"
                                  aria-label="Select a Timezone"
                                  data-control="select2"
                                  data-dropdown-parent="#kt_header_search"
                                  data-placeholder="Period"
                                  className="form-select form-select-sm form-select-solid"
                                >
                                  <option value="days">Days</option>
                                  <option value="weeks">Weeks</option>
                                  <option value="months">Months</option>
                                  <option value="years">Years</option>
                                </select>
                              </div>
                              {/*end::Col*/}
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Actions*/}
                            <div className="d-flex justify-content-end">
                              <button
                                type="reset"
                                className="btn btn-sm btn-light fw-bold btn-active-light-primary me-2"
                                data-kt-search-element="advanced-options-form-cancel"
                              >
                                Cancel
                              </button>
                              <a
                                href="utilities/search/horizontal.html"
                                className="btn btn-sm fw-bold btn-primary"
                                data-kt-search-element="advanced-options-form-search"
                              >
                                Search
                              </a>
                            </div>
                            {/*end::Actions*/}
                          </form>
                          {/*end::Preferences*/}
                          {/*begin::Preferences*/}
                          <form
                            data-kt-search-element="preferences"
                            className="pt-1 d-none"
                          >
                            {/*begin::Heading*/}
                            <h3 className="fw-semibold text-gray-900 mb-7">
                              Search Preferences
                            </h3>
                            {/*end::Heading*/}
                            {/*begin::Input group*/}
                            <div className="pb-4 border-bottom">
                              <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                                <span className="form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2">
                                  Projects
                                </span>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue={1}
                                  defaultChecked="checked"
                                />
                              </label>
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className="py-4 border-bottom">
                              <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                                <span className="form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2">
                                  Targets
                                </span>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue={1}
                                  defaultChecked="checked"
                                />
                              </label>
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className="py-4 border-bottom">
                              <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                                <span className="form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2">
                                  Affiliate Programs
                                </span>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue={1}
                                />
                              </label>
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className="py-4 border-bottom">
                              <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                                <span className="form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2">
                                  Referrals
                                </span>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue={1}
                                  defaultChecked="checked"
                                />
                              </label>
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className="py-4 border-bottom">
                              <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                                <span className="form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2">
                                  Users
                                </span>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue={1}
                                />
                              </label>
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Actions*/}
                            <div className="d-flex justify-content-end pt-7">
                              <button
                                type="reset"
                                className="btn btn-sm btn-light fw-bold btn-active-light-primary me-2"
                                data-kt-search-element="preferences-dismiss"
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="btn btn-sm fw-bold btn-primary"
                              >
                                Save Changes
                              </button>
                            </div>
                            {/*end::Actions*/}
                          </form>
                          {/*end::Preferences*/}
                        </div>
                      </div>
                      {/*end::Search*/}
                    </div>
                    {/*end::Search*/}
                    {/*begin::Activities*/}
                    <div className="d-flex align-items-stretch">
                      {/*begin::drawer toggle*/}
                      <div
                        className="topbar-item px-3 px-lg-4"
                        id="kt_activities_toggle"
                      >
                        <i className="ki-outline ki-js-2 fs-1" />
                      </div>
                      {/*end::drawer toggle*/}
                    </div>
                    {/*end::Activities*/}
                    {/*begin::Notifications*/}
                    <div className="d-flex align-items-stretch">
                      {/*begin::Menu wrapper*/}
                      <div
                        className="topbar-item px-3 px-lg-4 position-relative"
                        data-kt-menu-trigger="click"
                        data-kt-menu-attach="parent"
                        data-kt-menu-placement="bottom-end"
                        data-kt-menu-flip="bottom"
                      >
                        <i className="ki-outline ki-soft-3 fs-1" />
                      </div>
                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
                        data-kt-menu="true"
                        id="kt_menu_notifications"
                      >
                        {/*begin::Heading*/}
                        <div
                          className="d-flex flex-column bgi-no-repeat rounded-top"
                          style={{
                            backgroundImage:
                              'url("assets/media/misc/menu-header-bg.jpg")',
                          }}
                        >
                          <h3 className="text-white fw-semibold px-9 mt-10 mb-6">
                            Notifications
                            <span className="fs-8 opacity-75 ps-3">
                              24 reports
                            </span>
                          </h3>
                          {/*begin::Tabs*/}
                          <ul className="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-semibold px-9">
                            <li className="nav-item">
                              <a
                                className="nav-link text-white opacity-75 opacity-state-100 pb-4"
                                data-bs-toggle="tab"
                                href="#kt_topbar_notifications_1"
                              >
                                Alerts
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link text-white opacity-75 opacity-state-100 pb-4 active"
                                data-bs-toggle="tab"
                                href="#kt_topbar_notifications_2"
                              >
                                Updates
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link text-white opacity-75 opacity-state-100 pb-4"
                                data-bs-toggle="tab"
                                href="#kt_topbar_notifications_3"
                              >
                                Logs
                              </a>
                            </li>
                          </ul>
                          {/*end::Tabs*/}
                        </div>
                        {/*end::Heading*/}
                        {/*begin::Tab content*/}
                        <div className="tab-content">
                          {/*begin::Tab panel*/}
                          <div
                            className="tab-pane fade"
                            id="kt_topbar_notifications_1"
                            role="tabpanel"
                          >
                            {/*begin::Items*/}
                            <div className="scroll-y mh-325px my-5 px-8">
                              {/*begin::Item*/}
                              <div className="d-flex flex-stack py-4">
                                {/*begin::Section*/}
                                <div className="d-flex align-items-center">
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-35px me-4">
                                    <span className="symbol-label bg-light-primary">
                                      <i className="ki-outline ki-abstract-28 fs-2 text-primary" />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="mb-0 me-2">
                                    <a
                                      href="/"
                                      className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                    >
                                      Project Alice
                                    </a>
                                    <div className="text-gray-500 fs-7">
                                      Phase 1 development
                                    </div>
                                  </div>
                                </div>
                                {/*end::Section*/}
                                {/*begin::Label*/}
                                <span className="badge badge-light fs-8">
                                  1 hr
                                </span>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex flex-stack py-4">
                                {/*begin::Section*/}
                                <div className="d-flex align-items-center">
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-35px me-4">
                                    <span className="symbol-label bg-light-danger">
                                      <i className="ki-outline ki-information fs-2 text-danger" />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="mb-0 me-2">
                                    <a
                                      href="/"
                                      className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                    >
                                      HR Confidential
                                    </a>
                                    <div className="text-gray-500 fs-7">
                                      Confidential staff documents
                                    </div>
                                  </div>
                                </div>
                                {/*end::Section*/}
                                {/*begin::Label*/}
                                <span className="badge badge-light fs-8">
                                  2 hrs
                                </span>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex flex-stack py-4">
                                {/*begin::Section*/}
                                <div className="d-flex align-items-center">
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-35px me-4">
                                    <span className="symbol-label bg-light-warning">
                                      <i className="ki-outline ki-briefcase fs-2 text-warning" />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="mb-0 me-2">
                                    <a
                                      href="/"
                                      className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                    >
                                      Company HR
                                    </a>
                                    <div className="text-gray-500 fs-7">
                                      Corporeate staff profiles
                                    </div>
                                  </div>
                                </div>
                                {/*end::Section*/}
                                {/*begin::Label*/}
                                <span className="badge badge-light fs-8">
                                  5 hrs
                                </span>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex flex-stack py-4">
                                {/*begin::Section*/}
                                <div className="d-flex align-items-center">
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-35px me-4">
                                    <span className="symbol-label bg-light-success">
                                      <i className="ki-outline ki-abstract-12 fs-2 text-success" />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="mb-0 me-2">
                                    <a
                                      href="/"
                                      className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                    >
                                      Project Redux
                                    </a>
                                    <div className="text-gray-500 fs-7">
                                      New frontend admin theme
                                    </div>
                                  </div>
                                </div>
                                {/*end::Section*/}
                                {/*begin::Label*/}
                                <span className="badge badge-light fs-8">
                                  2 days
                                </span>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex flex-stack py-4">
                                {/*begin::Section*/}
                                <div className="d-flex align-items-center">
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-35px me-4">
                                    <span className="symbol-label bg-light-primary">
                                      <i className="ki-outline ki-colors-square fs-2 text-primary" />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="mb-0 me-2">
                                    <a
                                      href="/"
                                      className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                    >
                                      Project Breafing
                                    </a>
                                    <div className="text-gray-500 fs-7">
                                      Product launch status update
                                    </div>
                                  </div>
                                </div>
                                {/*end::Section*/}
                                {/*begin::Label*/}
                                <span className="badge badge-light fs-8">
                                  21 Jan
                                </span>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex flex-stack py-4">
                                {/*begin::Section*/}
                                <div className="d-flex align-items-center">
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-35px me-4">
                                    <span className="symbol-label bg-light-info">
                                      <i className="ki-outline ki-picture fs-2 text-info" />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="mb-0 me-2">
                                    <a
                                      href="/"
                                      className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                    >
                                      Banner Assets
                                    </a>
                                    <div className="text-gray-500 fs-7">
                                      Collection of banner images
                                    </div>
                                  </div>
                                </div>
                                {/*end::Section*/}
                                {/*begin::Label*/}
                                <span className="badge badge-light fs-8">
                                  21 Jan
                                </span>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex flex-stack py-4">
                                {/*begin::Section*/}
                                <div className="d-flex align-items-center">
                                  {/*begin::Symbol*/}
                                  <div className="symbol symbol-35px me-4">
                                    <span className="symbol-label bg-light-warning">
                                      <i className="ki-outline ki-color-swatch fs-2 text-warning" />
                                    </span>
                                  </div>
                                  {/*end::Symbol*/}
                                  <div className="mb-0 me-2">
                                    <a
                                      href="/"
                                      className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                    >
                                      Icon Assets
                                    </a>
                                    <div className="text-gray-500 fs-7">
                                      Collection of SVG icons
                                    </div>
                                  </div>
                                </div>
                                {/*end::Section*/}
                                {/*begin::Label*/}
                                <span className="badge badge-light fs-8">
                                  20 March
                                </span>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                            </div>
                            {/*end::Items*/}
                            {/*begin::View more*/}
                            <div className="py-3 text-center border-top">
                              <a
                                href="pages/user-profile/activity.html"
                                className="btn btn-color-gray-600 btn-active-color-primary"
                              >
                                View All
                                <i className="ki-outline ki-arrow-right fs-5" />
                              </a>
                            </div>
                            {/*end::View more*/}
                          </div>
                          {/*end::Tab panel*/}
                          {/*begin::Tab panel*/}
                          <div
                            className="tab-pane fade show active"
                            id="kt_topbar_notifications_2"
                            role="tabpanel"
                          >
                            <div className="d-flex flex-column px-9">
                              {/*begin::Section*/}
                              <div className="pt-10 pb-0">
                                <h3 className="text-gray-900 text-center fw-bold">
                                  Get Pro Access
                                </h3>
                                <div className="text-center text-gray-600 fw-semibold pt-1">
                                  Outlines keep you honest. They stoping you
                                  from amazing poorly about drive
                                </div>
                                {/*begin::Action*/}
                                <div className="text-center mt-5 mb-9">
                                  <a
                                    href="/"
                                    className="btn btn-sm btn-primary px-6"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_upgrade_plan"
                                  >
                                    Upgrade
                                  </a>
                                </div>
                                {/*end::Action*/}
                              </div>
                              {/*end::Section*/}
                              {/*begin::Illustration*/}
                              <div className="text-center px-4">
                                <img
                                  className="mw-100 mh-200px"
                                  alt=""
                                  src="assets/media/illustrations/unitedpalms-1/1.png"
                                />
                              </div>
                              {/*end::Illustration*/}
                            </div>
                          </div>
                          {/*end::Tab panel*/}
                          {/*begin::Tab panel*/}
                          <div
                            className="tab-pane fade"
                            id="kt_topbar_notifications_3"
                            role="tabpanel"
                          >
                            {/*begin::Items*/}
                            <div className="scroll-y mh-325px my-5 px-8">
                              {/*begin::Item*/}
                              <div className="d-flex flex-stack py-4">
                                {/*begin::Section*/}
                                <div className="d-flex align-items-center me-2">
                                  {/*begin::Code*/}
                                  <span className="w-70px badge badge-light-success me-4">
                                    200 OK
                                  </span>
                                  {/*end::Code*/}
                                  <a
                                    href="/"
                                    className="text-gray-800 text-hover-primary fw-semibold"
                                  >
                                    New order
                                  </a>
                                </div>
                                {/*end::Section*/}
                                {/*begin::Label*/}
                                <span className="badge badge-light fs-8">
                                  Just now
                                </span>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex flex-stack py-4">
                                {/*begin::Section*/}
                                <div className="d-flex align-items-center me-2">
                                  {/*begin::Code*/}
                                  <span className="w-70px badge badge-light-danger me-4">
                                    500 ERR
                                  </span>
                                  {/*end::Code*/}
                                  <a
                                    href="/"
                                    className="text-gray-800 text-hover-primary fw-semibold"
                                  >
                                    New customer
                                  </a>
                                </div>
                                {/*end::Section*/}
                                {/*begin::Label*/}
                                <span className="badge badge-light fs-8">
                                  2 hrs
                                </span>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex flex-stack py-4">
                                {/*begin::Section*/}
                                <div className="d-flex align-items-center me-2">
                                  {/*begin::Code*/}
                                  <span className="w-70px badge badge-light-success me-4">
                                    200 OK
                                  </span>
                                  {/*end::Code*/}
                                  <a
                                    href="/"
                                    className="text-gray-800 text-hover-primary fw-semibold"
                                  >
                                    Payment process
                                  </a>
                                </div>
                                {/*end::Section*/}
                                {/*begin::Label*/}
                                <span className="badge badge-light fs-8">
                                  5 hrs
                                </span>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex flex-stack py-4">
                                {/*begin::Section*/}
                                <div className="d-flex align-items-center me-2">
                                  {/*begin::Code*/}
                                  <span className="w-70px badge badge-light-warning me-4">
                                    300 WRN
                                  </span>
                                  {/*end::Code*/}
                                  <a
                                    href="/"
                                    className="text-gray-800 text-hover-primary fw-semibold"
                                  >
                                    Search query
                                  </a>
                                </div>
                                {/*end::Section*/}
                                {/*begin::Label*/}
                                <span className="badge badge-light fs-8">
                                  2 days
                                </span>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex flex-stack py-4">
                                {/*begin::Section*/}
                                <div className="d-flex align-items-center me-2">
                                  {/*begin::Code*/}
                                  <span className="w-70px badge badge-light-success me-4">
                                    200 OK
                                  </span>
                                  {/*end::Code*/}
                                  <a
                                    href="/"
                                    className="text-gray-800 text-hover-primary fw-semibold"
                                  >
                                    API connection
                                  </a>
                                </div>
                                {/*end::Section*/}
                                {/*begin::Label*/}
                                <span className="badge badge-light fs-8">
                                  1 week
                                </span>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex flex-stack py-4">
                                {/*begin::Section*/}
                                <div className="d-flex align-items-center me-2">
                                  {/*begin::Code*/}
                                  <span className="w-70px badge badge-light-success me-4">
                                    200 OK
                                  </span>
                                  {/*end::Code*/}
                                  <a
                                    href="/"
                                    className="text-gray-800 text-hover-primary fw-semibold"
                                  >
                                    Database restore
                                  </a>
                                </div>
                                {/*end::Section*/}
                                {/*begin::Label*/}
                                <span className="badge badge-light fs-8">
                                  Mar 5
                                </span>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex flex-stack py-4">
                                {/*begin::Section*/}
                                <div className="d-flex align-items-center me-2">
                                  {/*begin::Code*/}
                                  <span className="w-70px badge badge-light-warning me-4">
                                    300 WRN
                                  </span>
                                  {/*end::Code*/}
                                  <a
                                    href="/"
                                    className="text-gray-800 text-hover-primary fw-semibold"
                                  >
                                    System update
                                  </a>
                                </div>
                                {/*end::Section*/}
                                {/*begin::Label*/}
                                <span className="badge badge-light fs-8">
                                  May 15
                                </span>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex flex-stack py-4">
                                {/*begin::Section*/}
                                <div className="d-flex align-items-center me-2">
                                  {/*begin::Code*/}
                                  <span className="w-70px badge badge-light-warning me-4">
                                    300 WRN
                                  </span>
                                  {/*end::Code*/}
                                  <a
                                    href="/"
                                    className="text-gray-800 text-hover-primary fw-semibold"
                                  >
                                    Server OS update
                                  </a>
                                </div>
                                {/*end::Section*/}
                                {/*begin::Label*/}
                                <span className="badge badge-light fs-8">
                                  Apr 3
                                </span>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex flex-stack py-4">
                                {/*begin::Section*/}
                                <div className="d-flex align-items-center me-2">
                                  {/*begin::Code*/}
                                  <span className="w-70px badge badge-light-warning me-4">
                                    300 WRN
                                  </span>
                                  {/*end::Code*/}
                                  <a
                                    href="/"
                                    className="text-gray-800 text-hover-primary fw-semibold"
                                  >
                                    API rollback
                                  </a>
                                </div>
                                {/*end::Section*/}
                                {/*begin::Label*/}
                                <span className="badge badge-light fs-8">
                                  Jun 30
                                </span>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex flex-stack py-4">
                                {/*begin::Section*/}
                                <div className="d-flex align-items-center me-2">
                                  {/*begin::Code*/}
                                  <span className="w-70px badge badge-light-danger me-4">
                                    500 ERR
                                  </span>
                                  {/*end::Code*/}
                                  <a
                                    href="/"
                                    className="text-gray-800 text-hover-primary fw-semibold"
                                  >
                                    Refund process
                                  </a>
                                </div>
                                {/*end::Section*/}
                                {/*begin::Label*/}
                                <span className="badge badge-light fs-8">
                                  Jul 10
                                </span>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex flex-stack py-4">
                                {/*begin::Section*/}
                                <div className="d-flex align-items-center me-2">
                                  {/*begin::Code*/}
                                  <span className="w-70px badge badge-light-danger me-4">
                                    500 ERR
                                  </span>
                                  {/*end::Code*/}
                                  <a
                                    href="/"
                                    className="text-gray-800 text-hover-primary fw-semibold"
                                  >
                                    Withdrawal process
                                  </a>
                                </div>
                                {/*end::Section*/}
                                {/*begin::Label*/}
                                <span className="badge badge-light fs-8">
                                  Sep 10
                                </span>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex flex-stack py-4">
                                {/*begin::Section*/}
                                <div className="d-flex align-items-center me-2">
                                  {/*begin::Code*/}
                                  <span className="w-70px badge badge-light-danger me-4">
                                    500 ERR
                                  </span>
                                  {/*end::Code*/}
                                  <a
                                    href="/"
                                    className="text-gray-800 text-hover-primary fw-semibold"
                                  >
                                    Mail tasks
                                  </a>
                                </div>
                                {/*end::Section*/}
                                {/*begin::Label*/}
                                <span className="badge badge-light fs-8">
                                  Dec 10
                                </span>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                            </div>
                            {/*end::Items*/}
                            {/*begin::View more*/}
                            <div className="py-3 text-center border-top">
                              <a
                                href="pages/user-profile/activity.html"
                                className="btn btn-color-gray-600 btn-active-color-primary"
                              >
                                View All
                                <i className="ki-outline ki-arrow-right fs-5" />
                              </a>
                            </div>
                            {/*end::View more*/}
                          </div>
                          {/*end::Tab panel*/}
                        </div>
                        {/*end::Tab content*/}
                      </div>
                      {/*end::Menu wrapper*/}
                    </div>
                    {/*end::Notifications*/}
                    {/*begin::Chat*/}
                    <div className="d-flex align-items-stretch">
                      {/*begin::Menu wrapper*/}
                      <div
                        className="topbar-item px-3 px-lg-4 position-relative"
                        id="kt_drawer_chat_toggle"
                      >
                        <i className="ki-outline ki-tablet-text-down fs-1" />
                        <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 mt-4 start-50 animation-blink" />
                      </div>
                      {/*end::Menu wrapper*/}
                    </div>
                    {/*end::Chat*/}
                    {/*begin::Quick links*/}
                    <div className="d-flex align-items-stretch">
                      {/*begin::Menu wrapper*/}
                      <div
                        className="topbar-item px-3 px-lg-4"
                        data-kt-menu-trigger="click"
                        data-kt-menu-attach="parent"
                        data-kt-menu-placement="bottom-end"
                        data-kt-menu-flip="bottom"
                      >
                        <i className="ki-outline ki-chart-simple-2 fs-1" />
                      </div>
                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px"
                        data-kt-menu="true"
                      >
                        {/*begin::Heading*/}
                        <div
                          className="d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10"
                          style={{
                            backgroundImage:
                              'url("assets/media/misc/menu-header-bg.jpg")',
                          }}
                        >
                          <h3 className="text-white fw-semibold mb-3">
                            Quick Links
                          </h3>
                          {/*begin::Status*/}
                          <span className="badge bg-primary text-inverse-primary py-2 px-3">
                            25 pending tasks
                          </span>
                          {/*end::Status*/}
                        </div>
                        {/*end::Heading*/}
                        {/*begin:Nav*/}
                        <div className="row g-0">
                          {/*begin:Item*/}
                          <div className="col-6">
                            <a
                              href="apps/projects/budget.html"
                              className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom"
                            >
                              <i className="ki-outline ki-dollar fs-3x text-primary mb-2" />
                              <span className="fs-5 fw-semibold text-gray-800 mb-0">
                                Accounting
                              </span>
                              <span className="fs-7 text-gray-500">
                                eCommerce
                              </span>
                            </a>
                          </div>
                          {/*end:Item*/}
                          {/*begin:Item*/}
                          <div className="col-6">
                            <a
                              href="apps/projects/settings.html"
                              className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom"
                            >
                              <i className="ki-outline ki-sms fs-3x text-primary mb-2" />
                              <span className="fs-5 fw-semibold text-gray-800 mb-0">
                                Administration
                              </span>
                              <span className="fs-7 text-gray-500">
                                Console
                              </span>
                            </a>
                          </div>
                          {/*end:Item*/}
                          {/*begin:Item*/}
                          <div className="col-6">
                            <a
                              href="apps/projects/list.html"
                              className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end"
                            >
                              <i className="ki-outline ki-abstract-41 fs-3x text-primary mb-2" />
                              <span className="fs-5 fw-semibold text-gray-800 mb-0">
                                Projects
                              </span>
                              <span className="fs-7 text-gray-500">
                                Pending Tasks
                              </span>
                            </a>
                          </div>
                          {/*end:Item*/}
                          {/*begin:Item*/}
                          <div className="col-6">
                            <a
                              href="apps/projects/users.html"
                              className="d-flex flex-column flex-center h-100 p-6 bg-hover-light"
                            >
                              <i className="ki-outline ki-briefcase fs-3x text-primary mb-2" />
                              <span className="fs-5 fw-semibold text-gray-800 mb-0">
                                Customers
                              </span>
                              <span className="fs-7 text-gray-500">
                                Latest cases
                              </span>
                            </a>
                          </div>
                          {/*end:Item*/}
                        </div>
                        {/*end:Nav*/}
                        {/*begin::View more*/}
                        <div className="py-2 text-center border-top">
                          <a
                            href="pages/user-profile/activity.html"
                            className="btn btn-color-gray-600 btn-active-color-primary"
                          >
                            View All
                            <i className="ki-outline ki-arrow-right fs-5" />
                          </a>
                        </div>
                        {/*end::View more*/}
                      </div>
                      {/*end::Menu wrapper*/}
                    </div>
                    {/*end::Quick links*/}
                    {/*begin::Theme mode*/}
                    <div className="d-flex align-items-center">
                      {/*begin::Menu toggle*/}
                      <a
                        href="/"
                        className="topbar-item px-3 px-lg-4"
                        data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                        data-kt-menu-attach="parent"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-night-day theme-light-show fs-1" />
                        <i className="ki-outline ki-moon theme-dark-show fs-1" />
                      </a>
                      {/*begin::Menu toggle*/}
                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px"
                        data-kt-menu="true"
                        data-kt-element="theme-mode-menu"
                      >
                        <div className="menu-item px-3 my-0">
                          <a
                            href="/"
                            className="menu-link px-3 py-2"
                            data-kt-element="mode"
                            data-kt-value="light"
                          >
                            <span className="menu-icon" data-kt-element="icon">
                              <i className="ki-outline ki-night-day fs-2" />
                            </span>
                            <span className="menu-title">Light</span>
                          </a>
                        </div>
                        <div className="menu-item px-3 my-0">
                          <a
                            href="/"
                            className="menu-link px-3 py-2"
                            data-kt-element="mode"
                            data-kt-value="dark"
                          >
                            <span className="menu-icon" data-kt-element="icon">
                              <i className="ki-outline ki-moon fs-2" />
                            </span>
                            <span className="menu-title">Dark</span>
                          </a>
                        </div>
                        <div className="menu-item px-3 my-0">
                          <a
                            href="/"
                            className="menu-link px-3 py-2"
                            data-kt-element="mode"
                            data-kt-value="system"
                          >
                            <span className="menu-icon" data-kt-element="icon">
                              <i className="ki-outline ki-screen fs-2" />
                            </span>
                            <span className="menu-title">System</span>
                          </a>
                        </div>
                      </div>
                    </div>










                    <div className="user-menu-container">
      {/* Avatar trigger */}
      <div
        id="avatar_trigger"
        className="topbar-item cursor-pointer symbol px-3 px-lg-5 me-n3 me-lg-n5 symbol-30px symbol-md-35px"
        onClick={toggleMenu} // Call toggleMenu when avatar is clicked
      >
        <img src="assets/media/avatars/300-1.jpg" alt="user-avatar" />
      </div>

      {/* User account menu */}
      {menuOpen && (
        <div className="user-account-menu">
          <div className="menu-item">
            <div className="menu-content d-flex align-items-center">
              {/* Avatar in the menu */}
              <div className="menu-avatar">
                <img alt="Avatar" src="assets/media/avatars/300-1.jpg" />
              </div>

              <div className="menu-user-info">
                <div className="user-name">
                  Max Smith
                  <span className="user-status">Pro</span>
                </div>
                <a href="/" className="user-email">max@kt.com</a>
              </div>
            </div>
          </div>

          {/* Other menu items */}
          <div className="menu-separator" />
          <div className="menu-item">
            <a href="account/overview.html" className="menu-link">My Profile</a>
          </div>
          <div className="menu-item">
            <a  className="menu-link"  onClick={handleLogout}>Sign Out</a>
          </div>
        </div>
      )}
    </div>
















                    <div
                      className="d-flex align-items-stretch d-lg-none px-3 me-n3"
                      title="Show header menu"
                    >
                      <div
                        className="topbar-item"
                        id="kt_header_menu_mobile_toggle"
                      >
                        <i className="ki-outline ki-burger-menu-2 fs-1" />
                      </div>
                    </div>
                    {/*end::Heaeder menu toggle*/}
                  </div>

                </div>
              </div>
            </div>

   </>
  )
}

export default Header