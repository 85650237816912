import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import Swal from "sweetalert2";

const CrudGroup = () => {
  const navigate = useNavigate();
  const [selectedData, setSelectedData] = useState([]);

  const [groups, setGroups] = useState([]);
  const [formData, setFormData] = useState({
    group_name: "",
    parent: "",
    position: "",
  });

  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = async () => {
    try {
      const response = await fetch(
        "https://admissioninnepal.in/api/group-list",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: qs.stringify({ key: 12321 }),
        }
      );

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.json();
      setGroups(data.data);
      console.log("paren", data.data);
    } catch (error) {
      console.error("Fetching group data failed", error);
    }
  };

  useEffect(() => {
    // Fetch data based on formData
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://admissioninnepal.in/api/group-list",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify({
              key: "12321", // Replace with your actual key
              parent: formData.parent,
              position: formData.position,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        if (data.responsecode === 200) {
          setSelectedData(data.data);
        } else {
          console.error("Failed to fetch data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Fetch data only if both parent and position are selected
    if (formData.parent && formData.position) {
      fetchData();
    }
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddGroup = async (e) => {
    e.preventDefault();
    try {
      const data = qs.stringify({
        ...formData,
        key: 12321,
      });

      await axios.post("https://admissioninnepal.in/api/add-edit-group", data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      fetchGroups();
      Swal.fire({
        title: "Success",
        text: "Group added successfully",
        icon: "success",
        confirmButtonText: "OK",
      });
      setFormData({
        group_name: "",
        parent: "",
        position: "",
      });
    } catch (error) {
      console.error("Error adding group:", error);
      Swal.fire({
        title: "Error",
        text: "Failed to add group",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleDeleteGroup = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const data = qs.stringify({
            id,
            key: 12321,
            table: "tbl_group"
          });

          await axios.post(
            "https://admissioninnepal.in/api/delete-group",
            data,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );
          fetchGroups();
          Swal.fire({
            title: "Deleted!",
            text: "Group has been deleted.",
            icon: "success",
            confirmButtonText: "OK",
          });
        } catch (error) {
          console.error("Error deleting group:", error);
          Swal.fire({
            title: "Error",
            text: "Failed to delete group",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };
  const hasChildren = (groupId) => {
    return groups.some((group) => group.parent === groupId);
  };

  const handleUpdateGroup = (id) => {
    navigate(`/edit-group/${id}`);
  };

  return (
    <>
      <Header />
      <Sidebar groups={groups} />
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
            <div
              id="kt_app_toolbar_container"
              className="app-container container-xxl d-flex flex-stack"
            >
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-5 text-center">
                  Add Crud Group
                </h1>
                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                  <li className="breadcrumb-item text-muted">
                    <Link
                      to="/dashboardpage"
                      className="text-muted text-hover-primary"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <span className="bullet bg-gray-500 w-5px h-2px" />
                  </li>
                  <li className="breadcrumb-item text-muted">Crud Group</li>
                </ul>
              </div>
            </div>
          </div>
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-xxl"
            >
              <div className="card">
                <div className="card-body p-0">
                  <div className="card-px my-10" id="c">
                    <div className="d-flex flex-column flex-lg-row mb-17">
                      <div className="flex-lg-row-fluid me-0 me-lg-20">
                        <div className="row gx-3">
                          <div className="col-lg-4 ">
                            <div className="gap-lg">
                              <h3>Add Group Data</h3>
                              <form
                                className="form mb-5 mt-3"
                                onSubmit={handleAddGroup}
                              >
                                <div className="form-group mb-3">
                                  <label>Group name</label>
                                  <input
                                    type="text"
                                    name="group_name"
                                    value={formData.group_name}
                                    onChange={handleInputChange}
                                    className="form-control mt-3"
                                  />
                                </div>

                                <div className="form-group mb-3">
                                  <label>Parent</label>
                                  <select
                                    name="parent"
                                    value={formData.parent}
                                    onChange={handleInputChange}
                                    className="form-select mt-3"
                                  >
                                    <option value="">Select Parent</option>
                                    {groups
                                      .filter((group) => group.parent == 0)
                                      .map((group) => (
                                        <option key={group.id} value={group.id}>
                                          {group.group_name}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="form-group mb-3">
                                  <label>Position</label>
                                  <select
                                    name="position"
                                    value={formData.position}
                                    onChange={handleInputChange}
                                    className="form-select mt-3"
                                  >
                                    <option value="">Select Position</option>
                                    {groups
                                      .filter(
                                        (group) =>
                                          group.parent === formData.parent
                                      )
                                      .map((group) => (
                                        <option key={group.id} value={group.id}>
                                          {group.group_name}
                                        </option>
                                      ))}
                                  </select>
                                </div>

                                <button
                                  type="submit"
                                  className="btn btn-primary mt-5 w-100"
                                >
                                  <span className="indicator-label">Add</span>
                                  <span className="indicator-progress">
                                    Please wait...{" "}
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                  </span>
                                </button>
                              </form>
                            </div>
                          </div>

                          <div className="col-lg-8">
                            <div className="tb">
                              <h3>Display List of Group</h3>
                              <table className="table table-rounded table-row-bordered border gy-7 gs-7 mt-5 p-5">
                                <thead>
                                  <tr
                                    className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200"
                                    id="thead"
                                  >
                                    <th>Group name</th>
                                    <th>Parent</th>
                                    <th>Position</th>
                                    <th className="text-end">Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {groups.map((group) => (
                                    <tr key={group.id}>
                                      <td>{group.group_name}</td>
                                      <td>
                                        {groups.find(
                                          (g) => g.id === group.parent
                                        )?.group_name || "No Parent"}
                                      </td>
                                      <td>{group.position}</td>
                                      <td className="text-end">
                                        <button
                                          className="btn btn-light-danger btn-sm"
                                          onClick={() =>
                                            handleDeleteGroup(group.id)
                                          }
                                          hidden={hasChildren(group.id)}
                                        >
                                          Delete
                                        </button>
                                        <button
                                          className="btn btn-light-warning btn-sm mx-3"
                                          onClick={() =>
                                            handleUpdateGroup(group.id)
                                          }
                                        >
                                          Edit
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          id="kt_app_footer"
          className="app-footer py-4 d-flex flex-column flex-md-row flex-stack"
        >
          <div className="text-dark order-2 order-md-1">
            <span className="text-muted fw-bold me-1">2024©</span>
            <a
              href="https://keenthemes.com"
              target="#"
              className="text-gray-800 text-hover-primary"
            >
              Keenthemes
            </a>
          </div>
          <div className="order-1 order-md-2">
            <a
              href="https://keenthemes.com"
              target="_blank"
              className="text-gray-800 text-hover-primary"
            >
              Privacy & Policy
            </a>
            <a
              href="https://keenthemes.com"
              target="_blank"
              className="ms-4 text-gray-800 text-hover-primary"
            >
              Terms & Conditions
            </a>
          </div>
        </div> */}
        <Footer />
      </div>
    </>
  );
};

export default CrudGroup;
