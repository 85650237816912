import React from 'react';

function ErrorPage() {
  // const error = useRouteError(); // Uncomment this if you want to handle dynamic errors

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h1 style={styles.errorCode}>404</h1>
        <p style={styles.message}>Oops! The page you're looking for doesn't exist.</p>
        <button style={styles.button} onClick={() => window.location.href = "/dashboardpage"}>
          Go to Home
        </button>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f8f9fa',
    textAlign: 'center',
  },
  content: {
    backgroundColor: '#ffffff',
    padding: '40px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  },
  errorCode: {
    fontSize: '80px',
    fontWeight: 'bold',
    color: '#ff6b6b',
  },
  message: {
    fontSize: '20px',
    margin: '20px 0',
    color: '#6c757d',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  }
};

export default ErrorPage;
