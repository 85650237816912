import "./App.css";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import { BrowserRouter as Router, Route, Routes, Form } from "react-router-dom";
// import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import Region from "./pages/Location/Region";
import RegionData from "./pages/Location/RegionData";

import SpecializationData from "./pages/Course/Specialization";
// import Test2 from "./pages/Test2";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import CrudGroup from "./pages/Crud/CrudGroup";
import EditGroup from "./pages/Crud/EditGroup";
import CrudFiled from "./pages/Crud/CrudFiled";
import Crud from "./pages/Crud/Crud";

import CrudForm from "./pages/Crud/Crud";
import YourComponent from "./pages/Test";
import SelectTableGetFeild from "./pages/Test2";
import FormBuilder from "./pages/Form/FormBuilder";
import CrudBuilder from "./pages/Crud/CrudBuilder";
import DisplayCrudTableData from "./pages/Crud/DisplayCrudTableData";
import DynamicCheckbox from "./pages/Crud/DynamicCheckbox";
import ChainedSelect from "./pages/Crud/ChainedSelect";

// import './main.css'

import './assets/css/style.bundle.css'

import Test2 from "./pages/Test2";
import GlobalForm from "./pages/Crud/GlobalForm";
import EditCrud from "./pages/Crud/EditCrud";
import EditForm from "./utitls/form/EditForm";
import ManipalForm from "./pages/Crud/ManipalForm";
import ErrorPage from "./ErrorPage";
import FetchMultipleApi from "./pages/Crud/FetchMultipleApi";
// import CrudCheckboxRadio from "./pages/Crud/CrudCheckboxRadio";


function App() {
  return (
    <Router>
      <Routes>

        <Route path="/" element={<Login />} />
        <Route path="/dashboardpage" element={<ProtectedRoute element={Dashboard} />} />

        <Route path="/test" element={<ProtectedRoute element={YourComponent} />} />
        <Route path="/test2" element={<Test2 />} />
        <Route path="/crud-group" element={<ProtectedRoute element={CrudGroup} />} />
        <Route path="/crud-field" element={<ProtectedRoute element={CrudFiled} />} />
        <Route path="/crud-form" element={<ProtectedRoute element={CrudForm} />} />


        <Route path="/edit-group/:id" element={<ProtectedRoute element={EditGroup} />} />

        
        <Route path="/form" element={<ProtectedRoute element={FormBuilder} />} />


        <Route path="/crud-builder" element={<ProtectedRoute element={CrudBuilder} />} />
        <Route path="/crudtabledata" element={<ProtectedRoute element={DisplayCrudTableData} />} />

        <Route path="/dynamic-checkbox" element={<ProtectedRoute element={DynamicCheckbox} />} />

        <Route path="/chained-select" element={<ProtectedRoute element={ChainedSelect} />} />

        <Route path="/edit-group/:id" element={<ProtectedRoute element={EditGroup} />} />

        <Route path="/edit-crud/:id" element={<ProtectedRoute element={EditCrud } />} />

        <Route path="/global-form/:slug" element={<GlobalForm />} />

        <Route path="/manipal" element={<ManipalForm />} />

        <Route path="/error-page" element={<ErrorPage />} />

        <Route path="/multiple-api" element={<FetchMultipleApi />} />

        
        <Route path="/edit-form/:id"
          element={<ProtectedRoute element={<EditForm />} />}
        />


      </Routes>


    </Router>
  );
}

export default App;




