

import React from 'react'

const ManipalForm = () => {
  return (
    <>

      <iframe src="http://localhost:3000/global-form/tbl_demo_manipal-d0c02aed95ffcff05eb78a42785fab5c79002d94" frameborder="0" style={{ height: "400px", width: "400px" }}></iframe>

      <button className='btn btn-primary'></button>

      
    </>
  )
}

export default ManipalForm