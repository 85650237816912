
import React from 'react'
import { Link } from 'react-router-dom'

const BreadCrumb = () => {
  return (
    <>
         <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
            <div
              id="kt_app_toolbar_container"
              className="app-container container-xxl d-flex flex-stack"
            >
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-5 text-center">
                  Add Crud
                </h1>
                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                  <li className="breadcrumb-item text-muted">
                    <Link
                      to="/dashboardpage"
                      className="text-muted text-hover-primary"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <span className="bullet bg-gray-500 w-5px h-2px" />
                  </li>
                  <li className="breadcrumb-item text-muted">Crud </li>
                </ul>
              </div>
            </div>
          </div>
    </>
  )
}

export default BreadCrumb