import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";

const CrudFiled = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
            <div
              id="kt_app_toolbar_container"
              className="app-container container-xxl d-flex flex-stack"
            >
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-5 text-center">
                  Add Crud 
                </h1>
                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                  <li className="breadcrumb-item text-muted">
                    <Link
                      to="/dashboardpage"
                      className="text-muted text-hover-primary"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <span className="bullet bg-gray-500 w-5px h-2px" />
                  </li>
                  <li className="breadcrumb-item text-muted">Crud </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-xxl"
            >
              <div className="card">
                <div className="card-body p-0">
                  <div className="card-px my-10" id="c">
                    <div className="d-flex flex-column flex-lg-row mb-17">
                      <div className="flex-lg-row-fluid me-0 me-lg-20">
                        <form
                          className="form mb-5"
                          //   onSubmit={
                          //     editData ? handleUpdateGroup : handleAddGroup
                          //   }
                        >
                          <div className="form-group">
                            <label>Group name</label>
                            <input
                              type="text"
                              name="group_name"
                              //   value={formData.group_name}
                              //   onChange={handleInputChange}
                              className="form-control"
                            />
                          </div>
                          <div className="form-group">
                            <label>Parent</label>
                            <select
                              name="parent"
                              //   value={formData.parent}
                              //   onChange={handleInputChange}
                              className="form-control"
                            >
                              <option value="">Select Parent</option>
                            </select>
                          </div>

                          {/* <div className="form-group">
                            <label>Position</label>
                            <select
                              name="position"
                              value={formData.position}
                              onChange={handleInputChange}
                              className="form-control"
                            >
                              <option value="">Select the position</option>
                              {groups.map((group) => (
                                <option key={group.id+1} value={group.id+1}>
                                  {group.group_name}
                                </option>
                              ))}
                            </select>
                          </div> */}
                          <button type="submit" className="btn btn-primary">
                            <span className="indicator-label">
                              {/* {editData ? "Update" : "Add"} */}
                            </span>
                            <span className="indicator-progress">
                              Please wait...{" "}
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          </button>
                        </form>

                        <div className="table-responsive">
                          <table className="table table-striped table-hover ">
                            <thead>
                              <tr>
                                <th>Group Name</th>
                                {/* <th>Parent</th>
                                <th>Position</th> */}
                                <th className="float-end">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                           
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CrudFiled;
