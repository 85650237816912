

export const options = {
  text: [
    { value: "required", label: "Required", inputIsVisible: 0 },
    { value: "maxLength", label: "Max Length", inputIsVisible: 1 },
    { value: "minLength", label: "Min Length", inputIsVisible: 1 },
    { value: "validEmail", label: "Valid Email", inputIsVisible: 0 },
    { value: "validEmails", label: "Valid Emails", inputIsVisible: 0 },
    { value: "regex", label: "Regex", inputIsVisible: 1 },
    // { value: "decimal", label: "Decimal", inputIsVisible: 0 },
    { value: "validUrl", label: "Valid URL", inputIsVisible: 0 },
    { value: "alpha", label: "Alpha", inputIsVisible: 0 },
    { value: "alphaNumeric", label: "Alpha Numeric", inputIsVisible: 0 },
    {
      value: "alphaNumericSpaces",
      label: "Alpha Numeric Spaces",
      inputIsVisible: 0,
    },
    { value: "validNumber", label: "Valid Number", inputIsVisible: 0 },
    // { value: "validDatetime", label: "Valid Datetime", inputIsVisible: 0 },
    // { value: "validDate", label: "Valid Date", inputIsVisible: 0 },
    {
      value: "validAlphaNumericSpacesUnderscores",
      label: "Valid Alpha Numeric Spaces Underscores",
    },
    { value: "matches", label: "Matches", inputIsVisible: 0 },
    { value: "validJson", label: "Valid Json", inputIsVisible: 0 },
    { value: "exactLength", label: "Exact Length", inputIsVisible: 1 },
    { value: "alphaDash", label: "Alpha Dash", inputIsVisible: 0 },
    { value: "integer", label: "Integer", inputIsVisible: 0 },
    { value: "differs", label: "Differs", inputIsVisible: 0 },
    { value: "isNatural", label: "Is Natural", inputIsVisible: 0 },
    {
      value: "isNaturalNoZero",
      label: "Is Natural No Zero",
      inputIsVisible: 0,
    },


    { value: "inList", label: "In List", inputIsVisible: 0 },
    { value: "validIp", label: "Valid Ip", inputIsVisible: 0 },
  ],
  number: [
    { value: "required", label: "Required", inputIsVisible: 0 },
    { value: "maxLength", label: "Max Length", inputIsVisible: 1 },
    { value: "minLength", label: "Min Length", inputIsVisible: 1 },

    { value: "regex", label: "Regex", inputIsVisible: 1 },
    { value: "decimal", label: "Decimal", inputIsVisible: 0 },

    { value: "alpha", label: "Alpha", inputIsVisible: 0 },
    { value: "alphaNumeric", label: "Alpha Numeric", inputIsVisible: 0 },
    {
      value: "alphaNumericSpaces",
      label: "Alpha Numeric Spaces",
      inputIsVisible: 0,
    },
    { value: "validNumber", label: "Valid Number", inputIsVisible: 0 },

    { value: "matches", label: "Matches", inputIsVisible: 0 },

    { value: "exactLength", label: "Exact Length", inputIsVisible: 0 },

    { value: "integer", label: "Integer", inputIsVisible: 0 },
    { value: "differs", label: "Differs", inputIsVisible: 0 },
    { value: "isNatural", label: "Is Natural", inputIsVisible: 0 },
    {
      value: "isNaturalNoZero",
      label: "Is Natural No Zero",
      inputIsVisible: 0,
    },
    // { value: "lessThan", label: "Less Than", inputIsVisible: 1 },
    // {
    //   value: "lessThanEqualTo",
    //   label: "Less Than Equal To",
    //   inputIsVisible: 1,
    // },
    // { value: "greaterThan", label: "Greater Than", inputIsVisible: 1 },
    // {
    //   value: "greaterThanEqualTo",
    //   label: "Greater Than Equal To",
    //   inputIsVisible: 1,
    // },
    { value: "inList", label: "In List", inputIsVisible: 0 },

  ],
  file: [
    { value: "required", label: "Required", inputIsVisible: 0 },
    { value: "maxSize", label: "File Size", inputIsVisible: 1 },
    { value: "maxWidth", label: "Max Width", inputIsVisible: 1 },
    { value: "maxHeight", label: "Max Height", inputIsVisible: 1 },
    {
      value: "allowedExtensions",
      label: "Allowed Extension",
      inputIsVisible: 1,
    },
  ],
  filemultiple: [
    { value: "required", label: "Required", inputIsVisible: 0 },
    { value: "maxSize", label: "File Size", inputIsVisible: 1 },
    { value: "maxWidth", label: "Max Width", inputIsVisible: 1 },
    { value: "maxHeight", label: "Max Height", inputIsVisible: 1 },
    {
      value: "allowedExtensions",
      label: "Allowed Extension",
      inputIsVisible: 1,
    },
  ],
  textarea: [
    { value: "required", label: "Required", inputIsVisible: 0 },
    { value: "maxLength", label: "Max Length", inputIsVisible: 1 },
    { value: "minLength", label: "Min Length", inputIsVisible: 1 },

    { value: "regex", label: "Regex", inputIsVisible: 1 },
    { value: "decimal", label: "Decimal", inputIsVisible: 0 },
    { value: "validUrl", label: "Valid URL", inputIsVisible: 0 },
    { value: "alpha", label: "Alpha", inputIsVisible: 0 },
    { value: "alphaNumeric", label: "Alpha Numeric", inputIsVisible: 0 },
    {
      value: "alphaNumericSpaces",
      label: "Alpha Numeric Spaces",
      inputIsVisible: 0,
    },
    { value: "validNumber", label: "Valid Number", inputIsVisible: 0 },
    // { value: "validDatetime", label: "Valid Datetime", inputIsVisible: 0 },
    // { value: "validDate", label: "Valid Date", inputIsVisible: 0 },
    {
      value: "validAlphaNumericSpacesUnderscores",
      label: "Valid Alpha Numeric Spaces Underscores",
    },
    // { value: "matches", label: "Matches", inputIsVisible: 0 },
    { value: "validJson", label: "Valid Json", inputIsVisible: 0 },
    { value: "exactlength", label: "Exact Length Url", inputIsVisible: 0 },
    { value: "validurl", label: "Valid Url", inputIsVisible: 0 },
    { value: "alphaDash", label: "Alpha Dash", inputIsVisible: 0 },
    { value: "integer", label: "Integer", inputIsVisible: 0 },
    { value: "differs", label: "Differs", inputIsVisible: 0 },
    // { value: "isNatural", label: "Is Natural", inputIsVisible: 0 },
    // {
    //   value: "isNaturalNoZero",
    //   label: "Is Natural No Zero",
    //   inputIsVisible: 0,
    // },
    // { value: "lessThan", label: "Less Than", inputIsVisible: 1 },
    // {
    //   value: "lessThanEqualTo",
    //   label: "Less Than Equal To",
    //   inputIsVisible: 1,
    // },
    // { value: "greaterThan", label: "Greater Than", inputIsVisible: 1 },
    // {
    //   value: "greaterThanEqualTo",
    //   label: "Greater Than Equal To",
    //   inputIsVisible: 1,
    // },
    { value: "inList", label: "In List", inputIsVisible: 0 },
    { value: "validIp", label: "Valid Ip", inputIsVisible: 0 },
  ],
  yesno: [
    { value: "required", label: "Required", inputIsVisible: 0 },
    { value: "maxLength", label: "Max Length", inputIsVisible: 1 },

    { value: "regex", label: "Regex", inputIsVisible: 1 },

    { value: "alpha", label: "Alpha", inputIsVisible: 0 },
  ],
  truefalse: [
    { value: "required", label: "Required", inputIsVisible: 0 },
    { value: "alpha", label: "Alpha", inputIsVisible: 0 },
    { value: "validNumber", label: "Valid Number", inputIsVisible: 0 },
  ],
  email: [
    { value: "required", label: "Required", inputIsVisible: 0 },
    { value: "maxLength", label: "Max Length", inputIsVisible: 1 },
    { value: "minLength", label: "Min Length", inputIsVisible: 1 },

    { value: "validemail", label: "Valid Email", inputIsVisible: 1 },
    { value: "validemails", label: "Valid Emails", inputIsVisible: 1 },

    { value: "regex", label: "Regex", inputIsVisible: 0 },

    { value: "matches", label: "Matches", inputIsVisible: 0 },

    { value: "differs", label: "Differs", inputIsVisible: 0 },
  ],
  password: [
    { value: "required", label: "Required", inputIsVisible: 0 },
    { value: "maxLength", label: "Max Length", inputIsVisible: 1 },
    { value: "minLength", label: "Min Length", inputIsVisible: 1 },

    { value: "regex", label: "Regex", inputIsVisible: 1 },

    { value: "alpha", label: "Alpha", inputIsVisible: 0 },
    { value: "alphaNumeric", label: "Alpha Numeric", inputIsVisible: 0 },
    {
      value: "alphaNumericSpaces",
      label: "Alpha Numeric Spaces",
      inputIsVisible: 0,
    },
    { value: "validNumber", label: "Valid Number", inputIsVisible: 0 },

    {
      value: "validAlphaNumericSpacesUnderscores",
      label: "Valid Alpha Numeric Spaces Underscores",
    },
    { value: "matches", label: "Matches", inputIsVisible: 0 },

    { value: "differs", label: "Differs", inputIsVisible: 0 },
  ],
  editor: [
    { value: "required", label: "Required", inputIsVisible: 0 },
    { value: "maxLength", label: "Max Length", inputIsVisible: 1 },
    { value: "minLength", label: "Min Length", inputIsVisible: 1 },

    { value: "regex", label: "Regex", inputIsVisible: 1 },

    { value: "alpha", label: "Alpha", inputIsVisible: 0 },
    { value: "alphaNumeric", label: "Alpha Numeric", inputIsVisible: 0 },
    {
      value: "alphaNumericSpaces",
      label: "Alpha Numeric Spaces",
      inputIsVisible: 0,
    },
    { value: "validNumber", label: "Valid Number", inputIsVisible: 0 },
    { value: "validDatetime", label: "Valid Datetime", inputIsVisible: 0 },
    { value: "validDate", label: "Valid Date", inputIsVisible: 0 },
    {
      value: "validAlphaNumericSpacesUnderscores",
      label: "Valid Alpha Numeric Spaces Underscores",
    },
    { value: "validJson", label: "Valid Json", inputIsVisible: 0 },

    { value: "differs", label: "Differs", inputIsVisible: 0 },
  ],
  checkboxes: [
    { value: "required", label: "Required", inputIsVisible: 0 },
    { value: "maxLength", label: "Max Length", inputIsVisible: 1 },

    { value: "regex", label: "Regex", inputIsVisible: 0 },

    {
      value: "validmaxselectedoption",
      label: "Valid Max Selected Option",
      inputIsVisible: 0,
    },
    {
      value: "validminselectedoption",
      label: "Valid Min Selected Option",
      inputIsVisible: 0,
    },

    { value: "differs", label: "Differs", inputIsVisible: 0 },
  ],
  year: [
    { value: "required", label: "Required", inputIsVisible: 0 },
    { value: "maxLength", label: "Max Length", inputIsVisible: 1 },
    { value: "minLength", label: "Min Length", inputIsVisible: 1 },
    { value: "regex", label: "Regex", inputIsVisible: 0 },




  ],

  time: [
    { value: "required", label: "Required", inputIsVisible: 0 },
    { value: "maxLength", label: "Max Length", inputIsVisible: 1 },
    { value: "minLength", label: "Min Length", inputIsVisible: 1 },
    { value: "regex", label: "Regex", inputIsVisible: 0 },




  ],
  datetime: [
    { value: "required", label: "Required", inputIsVisible: 0 },
    { value: "regex", label: "Regex", inputIsVisible: 1 },
    { value: "validDatetime", label: "Valid Datetime", inputIsVisible: 0 },
    { value: "validDate", label: "Valid Date", inputIsVisible: 0 },


  ],
  date: [
    { value: "required", label: "Required", inputIsVisible: 0 },
    { value: "regex", label: "Regex", inputIsVisible: 1 },

    { value: "validDate", label: "Valid Date", inputIsVisible: 0 },


  ],
  addressmap: [
    { value: "required", label: "Required", inputIsVisible: 0 },
    { value: "maxLength", label: "Max Length", inputIsVisible: 1 },
    { value: "minLength", label: "Min Length", inputIsVisible: 1 },
  ],
  custom_select: [
    { value: "required", label: "Required", inputIsVisible: 0},

  ],

  current_user_id: [
    { value: "required", label: "Required", inputIsVisible: 0},

  ],
  range: [],
  dynamic_select: [
    { value: "required", label: "Required", inputIsVisible: 0 },
  ],

  select_multiple: [
    { value: "required", label: "Required", inputIsVisible: 0 },

  ],

  custom_select_multiple: [
    { value: "required", label: "Required", inputIsVisible: 0 },

  ],


  chained: [
    { value: "required", label: "Required", inputIsVisible: 0 },

  ],
  custom_option: [
    { value: "required", label: "Required", inputIsVisible: 0 },

  ],
  custom_checkbox: [
    { value: "required", label: "Required", inputIsVisible: 0 },

  ],
  time: [
    { value: "required", label: "Required", inputIsVisible: 0 },

  ],
  timestamp: [
    { value: "required", label: "Required", inputIsVisible: 0 },

  ],
  year: [
    { value: "required", label: "Required", inputIsVisible: 0 },
  ],

};

export const Fileoptions = [
  { value: "maxSize", label: "File Size", inputIsVisible: 1 },
  { value: "maxWidth", label: "Max Width", inputIsVisible: 1 },
  { value: "maxHeight", label: "max Height", inputIsVisible: 1 },
  { value: "allowedExtensions", label: "Allowed Extension", inputIsVisible: 1 },
];
