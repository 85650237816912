function ValidateForm(pattern, value, formData) {
    let patternObject;
    try {
        patternObject = JSON.parse(pattern);
    } catch (error) {
        console.error("Error parsing JSON pattern:", error);
        return {
            isValid: false,
            errors: ["Invalid pattern format."],
        };
    }

    const errors = [];
    const regexPatterns = {}; // Object to store regex patterns


    // Convert the function to return a Promise
    return new Promise((resolve) => {
        const promises = [];

        function checkImageDimensions(file) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = function () {
                    resolve({ width: img.width, height: img.height });
                };
                img.onerror = function () {
                    reject('Error loading image.');
                };
                img.src = URL.createObjectURL(file);
            });
        }

        Object.entries(patternObject).forEach(([key, val]) => {
            switch (key) {
                case 'maxWidth':
                case 'maxHeight':
                    if (value instanceof File && value.type.startsWith('image/')) {
                        promises.push(
                            checkImageDimensions(value)
                                .then(({ width, height }) => {
                                    if (key === 'maxWidth' && width > parseInt(val)) {
                                        errors.push(`Image exceeds the maximum width of ${parseInt(val)} pixels.`);
                                    }
                                    if (key === 'maxHeight' && height > parseInt(val)) {
                                        errors.push(`Image exceeds the maximum height of ${parseInt(val)} pixels.`);
                                    }
                                })
                                .catch((error) => {
                                    errors.push(error);
                                })
                        );
                    }
                    break;

                case 'required':
                    if ((typeof value === 'string' && value.trim() === '') ||
                        (value instanceof File && value.size === 0) ||
                        (!value)) {
                        errors.push('This field is required.');
                    }
                    break;

                case "maxLength":
                    if (value && value.length > val) {
                        errors.push(`Input exceeds the maximum length of ${val} characters.`);
                    }
                    break;

                case 'minLength':
                    regexPatterns[key] = new RegExp(`^.{${val},}$`);
                    if (!regexPatterns[key].test(value)) {
                        errors.push(`Input is shorter than the minimum length of ${val} characters.`);
                    }
                    break;
                case 'validEmail':
                    regexPatterns[key] = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
                    if (!regexPatterns[key].test(value)) {
                        errors.push(`Invalid email format.`);
                    }
                    break;
                case 'validEmails':
                    regexPatterns[key] = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4},?)+$/;
                    if (!regexPatterns[key].test(value)) {
                        errors.push(`One or more emails are invalid.`);
                    }
                    break;
                case 'regex':
                    regexPatterns[key] = new RegExp(`^${val}$`);
                    if (!regexPatterns[key].test(value)) {
                        errors.push(`Input does not match the specified pattern.`);
                    }
                    break;
                case 'decimal':
                    regexPatterns[key] = /^\d+\.\d+$/;
                    if (!regexPatterns[key].test(value)) {
                        errors.push(`Invalid decimal number. A valid decimal must contain a decimal point (e.g., 72.5).`);
                    }
                    break;


                case 'validUrl':
                    regexPatterns[key] = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
                    if (!regexPatterns[key].test(value)) {
                        errors.push(`Invalid URL format.`);
                    }
                    break;
                case 'alpha':
                    regexPatterns[key] = /^[a-zA-Z]+$/;
                    if (!regexPatterns[key].test(value)) {
                        errors.push(`Only alphabetic characters are allowed.`);
                    }
                    break;
                case 'alphaNumeric':
                    regexPatterns[key] = /^[a-zA-Z0-9]+$/;
                    if (!regexPatterns[key].test(value)) {
                        errors.push(`Only alphanumeric characters are allowed.`);
                    }
                    break;
                case 'alphaNumericSpaces':
                    regexPatterns[key] = /^[a-zA-Z0-9 ]+$/;
                    if (!regexPatterns[key].test(value)) {
                        errors.push(`Only alphanumeric characters and spaces are allowed.`);
                    }
                    break;
                case 'validNumber':
                    regexPatterns[key] = /^\d+$/;
                    if (!regexPatterns[key].test(value)) {
                        errors.push(`Only numeric characters are allowed.`);
                    }
                    break;
                case 'validDatetime':
                    regexPatterns[key] = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
                    if (!regexPatterns[key].test(value)) {
                        errors.push(`Invalid datetime format. Use YYYY-MM-DDTHH:MM.`);
                    }
                    break;
                case 'validDate':
                    regexPatterns[key] = /^\d{4}-\d{2}-\d{2}$/;
                    if (!regexPatterns[key].test(value)) {
                        errors.push(`Invalid date format. Use YYYY-MM-DD.`);
                    }
                    break;
                case 'validAlphaNumericSpacesUnderscores':
                    regexPatterns[key] = /^[a-zA-Z0-9_ ]+$/;
                    if (!regexPatterns[key].test(value)) {
                        errors.push(`Only alphanumeric characters, spaces, and underscores are allowed.`);
                    }
                    break;
                case 'matches': {
                    // Log the key being validated

                    // Retrieve the pattern from formData using val
                    const data = formData[val];

                    // Create a regex using the retrieved pattern
                    regexPatterns[key] = new RegExp(data);

                    // Test the value against the regex pattern
                    if (!regexPatterns[key].test(value)) {
                        errors.push(`Input does not match the specified pattern.`);
                    }
                    break;
                }
                case 'validJson':
                    try {
                        JSON.parse(value);
                    } catch (e) {
                        errors.push(`Invalid JSON format.`);
                    }
                    break;
                case 'exactLength':
                    regexPatterns[key] = new RegExp(`^.{${val}}$`);
                    if (!regexPatterns[key].test(value)) {
                        errors.push(`Input must be exactly ${val} characters long.`);
                    }
                    break;

                case 'alphaDash':
                    regexPatterns[key] = /^[a-zA-Z0-9-_]+$/;
                    if (!regexPatterns[key].test(value)) {
                        errors.push(`Only alphanumeric characters, dashes, and underscores are allowed.`);
                    }
                    break;
                case 'integer':
                    regexPatterns[key] = /^-?\d+$/;
                    if (!regexPatterns[key].test(value)) {
                        errors.push(`Invalid integer format.`);
                    }
                    break;

                case 'isNatural':
                    regexPatterns[key] = /^\d+$/;
                    if (!regexPatterns[key].test(value)) {
                        errors.push(`Only natural numbers are allowed.`);
                    }
                    break;
                case 'isNaturalNoZero':
                    regexPatterns[key] = /^[1-9]\d*$/;
                    if (!regexPatterns[key].test(value)) {
                        errors.push(`Only natural numbers greater than zero are allowed.`);
                    }
                    break;
                case 'lessThan':
                    if (Number(value) >= Number(val)) {
                        errors.push(`Input must be less than ${val}.`);
                    }
                    break;
                case 'lessThanEqualTo':
                    if (Number(value) > Number(val)) {
                        errors.push(`Input must be less than or equal to ${val}.`);
                    }
                    break;
                case 'greaterThan':
                    if (Number(value) <= Number(val)) {
                        errors.push(`Input must be greater than ${val}.`);
                    }
                    break;
                case 'greaterThanEqualTo':
                    if (Number(value) < Number(val)) {
                        errors.push(`Input must be greater than or equal to ${val}.`);
                    }
                    break;
                case 'inList':
                    const list = val.split(',').map(v => v.trim());
                    if (!list.includes(value)) {
                        errors.push(`Input must be one of the following: ${list.join(', ')}.`);
                    }
                    break;
                case 'validIp':
                    regexPatterns[key] = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
                    if (!regexPatterns[key].test(value)) {
                        errors.push(`Invalid IP address format.`);
                    }
                    break;
                case 'maxSize':
                    // Assuming value is a File object
                    const fileSizeInKB = value.size / 1024; // Convert from bytes to kilobytes
                    if (fileSizeInKB > val) {
                        errors.push(`File exceeds the maximum size of ${val} KB.`);
                    }
             

                    break;


                    case "allowedExtensions":
                        try {
                         
                    
                            // Ensure that value is not undefined or null
                            if (!value) {
                                // throw new Error('Filename (value) is undefined or not provided.');
                            }
                    
                            // Split allowed extensions by comma, trim each extension, and join them into a single string separated by "|"
                            const extensions = val.split(",").map(ext => ext.trim()).join("|");
                    
                            // Construct regex pattern with escaped dot
                            const fileExtensionRegex = new RegExp(`\\.(${extensions})$`, 'i'); // 'i' for case-insensitivity
                    
                            // Function to validate a single file
                            const validateFile = (file) => {
                                const fileName = file.name;
                    
                                if (!fileExtensionRegex.test(fileName)) {
                                    errors.push(`File extension is not allowed. Allowed extensions: ${val}`);
                                    return false;
                                }
                                return true;
                            };
                    
                            // Check if value is an array (multiple files)
                            if (Array.isArray(value)) {
                                // Validate each file in the array
                                for (let file of value) {
                                    if (!validateFile(file)) {
                                        break; // Stop validation if any file fails
                                    }
                                }
                            } else {
                                // Validate a single file
                                validateFile(value);
                            }
                        } catch (error) {
                            // Handle the error gracefully and push the error message into the errors array
                            console.error(error.message);
                            errors.push(error.message);
                        }
                        break;
                    

                default:
                    break;
            }
        });


        Promise.all(promises).then(() => {
            resolve({
                isValid: errors.length === 0,
                errors,
            });
        });
    });
}

export default ValidateForm;
