import React from "react";
import Sidebar from "../components/Sidebar.jsx";
import Header from "../components/Header.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faStar } from "@fortawesome/free-solid-svg-icons"; // Example icon
import Footer from "../components/Footer.jsx";
import { Link } from "react-router-dom";
import CollegeImg from "../../src/img/college.png";

function Dashboard() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <Sidebar />
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <Header />

            <div
              className="content d-flex flex-column flex-column-fluid"
              id="kt_content cards"
            >
              {/*begin::Toolbar*/}
              <div className="toolbar" id="kt_toolbar">
                {/*begin::Container*/}
                <div
                  id="kt_toolbar_container"
                  className="container-fluid d-flex flex-stack"
                >
                  {/*begin::Page title*/}
                  <div
                    id="content_wrapper"
                    className="page-title d-flex justify-content-between align-items-center flex-wrap lh-1"
                    data-kt-swapper="true"
                    data-kt-swapper-mode="prepend"
                    data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                  >
                    <div id="multipurpose_section">
                      <h1 className="d-flex align-items-center text-gray-900 fw-bold my-1 fs-3">
                        Admin Dashboard
                      </h1>
                    </div>

                    <div id="breadcrumb_section">
                      <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-1">
                        <li className="breadcrumb-item text-muted">
                          <a
                            href="index.html"
                            className="text-muted text-hover-primary"
                          >
                            Home
                          </a>
                        </li>

                        <li className="breadcrumb-item">
                          <span className="bullet bg-gray-300 w-5px h-2px" />
                        </li>

                        <li className="">
                          <Link
                            to="/dashboardpage"
                            className="custom-dashboard-link"
                          >
                            Dashboards
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="container-fluid mt-5">
              <div className="row">
                <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5 col-lg-3" id="bg-card">
                  {/*begin::Symbol*/}
                  <div className="symbol symbol-30px me-5 mb-8">
                    <span className="symbol-label">
                      <i className="ki-duotone ki-flask fs-1 text-primary">
                        <span className="path1" />
                        <span className="path2" />
                      </i>
                    </span>
                  </div>

                  <div className="m-0">
                    <span className=" fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1 text-white">
                      37
                    </span>

                    <span className="text-white fw-semibold fs-6">Courses</span>
                  </div>
                </div>

              </div>
            </div>


            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
