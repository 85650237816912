

// // exportUtils.js

// export const exportData = (data, role, format) => {
//     // Dummy data for demonstration
//     const sampleData = [
//       { id: 1, name: "John Doe", email: "john@example.com" },
//       { id: 2, name: "Jane Doe", email: "jane@example.com" }
//     ];

//     let blob;
//     let fileName = `data_export.${format}`;

//     switch (format) {
//       case "excel":
//         blob = new Blob([JSON.stringify(sampleData)], { type: "application/vnd.ms-excel" });
//         break;
//       case "pdf":
//         blob = new Blob([JSON.stringify(sampleData)], { type: "application/pdf" });
//         break;
//       case "csv":
//         const csvContent = sampleData.map(e => e.name + "," + e.email).join("\n");
//         blob = new Blob([csvContent], { type: "text/csv" });
//         break;
//       case "zip":
//         blob = new Blob([JSON.stringify(sampleData)], { type: "application/zip" });
//         break;
//       default:
//         return;
//     }

//     const url = URL.createObjectURL(blob);
//     const a = document.createElement("a");
//     a.href = url;
//     a.download = fileName;
//     a.click();
//     URL.revokeObjectURL(url);
//   };




import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";
import JSZip from "jszip";

const exportExcel = (data) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  // XLSX.writeFile(wb, `data_export_${role}.xlsx`);
};

const exportPDF = (data) => {
  const doc = new jsPDF();
  doc.text(JSON.stringify(data, null, 2), 10, 10);
  // doc.save(`data_export_${role}.pdf`);
};

const exportCSV = (data) => {
  const csvContent = data.map(item => Object.values(item).join(",")).join("\n");
  const csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  // saveAs(csvBlob, `data_export_${role}.csv`);
};

const exportZIP = (data) => {
  const zip = new JSZip();
  zip.file("data.json", JSON.stringify(data));
  zip.generateAsync({ type: "blob" }).then(content => {
    // saveAs(content, `data_export_${role}.zip`);
  });
};

export const exportData = (data,  format) => {
  switch (format) {
    case "excel":
      exportExcel(data);
      break;
    case "pdf":
      exportPDF(data);
      break;
    case "csv":
      exportCSV(data);
      break;
    case "zip":
      exportZIP(data);
      break;
    default:
      console.error("Unsupported format:", format);
  }
};

