
export const filterTableData = (tableData, id, dependency, formData, dynamic_condition) => {
  if (!tableData[id]) return [];

  return tableData[id].filter(item => item[dependency] == formData[dynamic_condition]);
};


export const handleSelectChange = (e, setFormData, setSelectedValue) => {

  const { name, value } = e.target;
  // Update the selected value in state

  setSelectedValue(value)

  // Update the form data with the selected value
  setFormData((prevData) => ({ ...prevData, [name]: value }));
};




export const handleCustomSelectMultipleOption = (e, setFormData, setSelectedValue) => {

  const { name, value } = e.target;
  // Update the selected value in state

  setSelectedValue(value)

  // Update the form data with the selected value
  setFormData((prevData) => ({ ...prevData, [name]: value }));
};





// getSelectOption for multiple select

export function handleInputChange(e, setFormData) {
  const { name, value, type, checked, files } = e.target;

  let finalValue;

  if (type === 'file') {
    finalValue = files[0]; // Store the single file
  } else if (type === 'range') {
    finalValue = Number(value);
  } // Convert range value to a number
  else if (type === 'radio') {
    finalValue = value // Set 1 for "yes" and 0 for "no"
  } else if (type === 'checkbox') {
    finalValue = checked ? 1 : 0; // Set 1 if checked, else 0
  } else {
    finalValue = value;
  }

  setFormData((prevData) => ({
    ...prevData,
    [name]: finalValue,
  }));
}


export function handleMultipleFileChange(e, setFormData) {
  const { name, files } = e.target;

  setFormData((prevData) => {
    // Convert the FileList object to an array
    const newFiles = Array.from(files);

    // Merge the new files with any existing files in the state
    const updatedFiles = [...(prevData[name] || []), ...newFiles];

    return {
      ...prevData,
      [name]: updatedFiles,
    };
  });
}


export function getSelectOption(id, tableData, dynamic_field, dynamic_label) {

  return tableData[id]?.map(item => ({
    label: item[dynamic_label],
    value: item[dynamic_field]
  })) || [];
  
}

export const handleSelectMultipleOption = (selectedOptions, formData, setFormData) => {
  setFormData({
    ...formData,
    multiple_select: selectedOptions.map(option => option.value)
  });
};


export function handleTextEditorChange(e, name, setFormData) {

  const { htmlValue, textValue } = e
  const editorObj = {
    htmlValue: htmlValue,
    textValue: textValue.replace('\n','')
  }


  setFormData((prevData) => ({
    ...prevData,
    [name]: editorObj,
  }));
  
}


// e object given below

// delta
// : 
// Delta { ops: Array(2) }
// htmlValue
// :
// "<p>sh</p>"
// source
// :
// "user"
// textValue
// :
// "sh\n"


// e containe many key value pair extract htmlValue and textValue and create object named editorObj in key value pair.


export const handleRadioChange = (e, setFormData) => {

  const { name, value } = e.target

  setFormData((prevData) => ({
    ...prevData,
    [name]: value
  }))
};

export const handleCheckboxChange = (e, setFormData) => {
  const { name, value } = e.target
  setFormData((prevData) => ({
    ...prevData,
    [name]: value
  }))
};



export function handleChekboxesChange(e, value, setFormData) {
  const { name, checked } = e.target;

  setFormData((prevData) => {
    // Get the current array of values for this checkbox group
    const currentValues = Array.isArray(prevData[name]) ? prevData[name] : [];

    // If checked, add the value; otherwise, remove it
    const newValues = checked
      ? [...currentValues, value]
      : currentValues.filter((v) => v !== value);

    return {
      ...prevData,
      [name]: newValues, // Update the checkbox group with the new array
    };
  });
}


