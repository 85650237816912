

import qs from 'qs'; // Ensure qs is imported here



export function handleChainedDynamicData(e, fieldId, setCrudFieldData, setTableNameForDynamicSelect) {
    const { name, value } = e.target;

    console.log("aaa")
    console.log(fieldId)
    console.log(setCrudFieldData)
    console.log(setTableNameForDynamicSelect)
    //set selected table
    setTableNameForDynamicSelect(value)

    setCrudFieldData((prevData) =>
        prevData.map((field) =>
            field.id === fieldId
                ? { ...field, [name]: value } // Update the specific field based on name and value
                : field
        )
    );
}

export function handleCheckboxesDynamicData(e, fieldId, setCrudFieldData, setTableNameForFields) {
    const { name, value } = e.target;
    if (name === 'dynamic_table') {

        setTableNameForFields(value)
    }
    setCrudFieldData((prevData) =>
        prevData.map((field) =>
            field.id === fieldId
                ? { ...field, [name]: value } // Update the specific field based on name and value
                : field
        )
    );
}



export const handleDeleteValidation = (fieldId, key, setSelectedValues) => {
    console.log("handleDeleteValidation")
    console.log(setSelectedValues)

    setSelectedValues((prevValues) => {
        const updatedValues = { ...prevValues };
        delete updatedValues[fieldId][key];
        return updatedValues;
    });
};





export const handleDelete = (fieldId, optionIndex, setCrudFieldData) => {


    // if (selectedInputType === "custom_option") {



    setCrudFieldData((prevOptions) => {
        return prevOptions.map((field) => {
            if (field.id === fieldId) {
                // Remove the option at the given optionIndex
                const updatedOptions = field.options.filter((data, idx) => idx !== optionIndex);

                // Return the field with updated options
                return { ...field, options: updatedOptions };
            }
            return field; // Return other fields as is
        });
    });


    // setCustomOptions((prevOptions) => {

    //     const fieldOptions = prevOptions[fieldId] || [];
    //     const newOptions = fieldOptions.filter(
    //         (_, index) => index !== optionIndex
    //     );
    //     return { ...prevOptions, [fieldId]: newOptions };
    // });

    // } else if (selectedInputType === "custom_checkbox") {
    //     setCustomCheckbox((prevOptions) => {
    //         const fieldOptions = prevOptions[fieldId] || [];
    //         const newOptions = fieldOptions.filter(
    //             (_, index) => index !== optionIndex
    //         );
    //         return { ...prevOptions, [fieldId]: newOptions };
    //     });
    // }

    // else if (selectedInputType === "custom_select") {
    //     setCustomSelect((prevOptions) => {
    //         const fieldOptions = prevOptions[fieldId] || [];
    //         const newOptions = fieldOptions.filter(
    //             (_, index) => index !== optionIndex
    //         );
    //         return { ...prevOptions, [fieldId]: newOptions };
    //     });
    // }

    // else if (selectedInputType === "custom_select_multiple") {
    //     setCustomSelectMultiple((prevOptions) => {
    //         const fieldOptions = prevOptions[fieldId] || [];
    //         const newOptions = fieldOptions.filter(
    //             (_, index) => index !== optionIndex
    //         );
    //         return { ...prevOptions, [fieldId]: newOptions };
    //     });
    // }
};


// previous handle delete code


// export const handleDelete = (fieldId, optionIndex, setCustomOptions, setCustomCheckbox, setCustomSelect, setCustomSelectMultiple, selectedInputType) => {
//     console.log("selectedInputType")
//     console.log(selectedInputType)

//     if (selectedInputType === "custom_option") {

//         setCustomOptions((prevOptions) => {

//             const fieldOptions = prevOptions[fieldId] || [];
//             const newOptions = fieldOptions.filter(
//                 (_, index) => index !== optionIndex
//             );
//             return { ...prevOptions, [fieldId]: newOptions };
//         });
//     } else if (selectedInputType === "custom_checkbox") {
//         setCustomCheckbox((prevOptions) => {
//             const fieldOptions = prevOptions[fieldId] || [];
//             const newOptions = fieldOptions.filter(
//                 (_, index) => index !== optionIndex
//             );
//             return { ...prevOptions, [fieldId]: newOptions };
//         });
//     }

//     else if (selectedInputType === "custom_select") {
//         setCustomSelect((prevOptions) => {
//             const fieldOptions = prevOptions[fieldId] || [];
//             const newOptions = fieldOptions.filter(
//                 (_, index) => index !== optionIndex
//             );
//             return { ...prevOptions, [fieldId]: newOptions };
//         });
//     }

//     else if (selectedInputType === "custom_select_multiple") {
//         setCustomSelectMultiple((prevOptions) => {
//             const fieldOptions = prevOptions[fieldId] || [];
//             const newOptions = fieldOptions.filter(
//                 (_, index) => index !== optionIndex
//             );
//             return { ...prevOptions, [fieldId]: newOptions };
//         });
//     }
// };



export const fetchCrudTableList = async (setCrudTableName) => {
    try {

        const response = await fetch(
            "https://admissioninnepal.in/api/tables",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: qs.stringify({ key: 12321 }),
            }
        );

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();

        setCrudTableName(data.data);
    } catch (error) {
        console.error("Fetching group data failed", error);
    }
};

export const fetchtableList = async (setTableName) => {
    try {

        const response = await fetch(
            "https://admissioninnepal.in/api/table-list",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: qs.stringify({ key: 12321 }),
            }
        );

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();

        setTableName(data.data);
    } catch (error) {
        console.error("Fetching group data failed", error);
    }
};

export const fetchFieldName = async (selectedTableName, setTableFields, setIsTableVisible) => {
    if (!selectedTableName) return; // Avoid API call if selectedTableName is empty

    const tableName = selectedTableName.replace("tbl_", "");
    console.log(tableName);
    try {
        const response = await fetch(
            `https://admissioninnepal.in/api/get-column/${tableName}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: qs.stringify({ key: 12321 }),
            }
        );

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log("table fields", data);
        setTableFields(data);
        setIsTableVisible(true);
    } catch (error) {
        console.error("Fetching table fields failed", error);
    }
};

// fetch tbl_group data
export const fetchGroupList = async (setGroups) => {
    try {
        const response = await fetch(
            "https://admissioninnepal.in/api/group-list",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: qs.stringify({ key: 12321 }),
            }
        );

        if (!response.ok) {
            throw new Error("Network response was not ok:");
        }
        const data = await response.json();
        setGroups(data.data);
    } catch (error) {
        console.error("Fetching group data failed", error);
    }
};

export const filterPositionFromGroup = async (setSelectedData, crudTableData) => {
    try {
        const response = await fetch(
            "https://admissioninnepal.in/api/group-list",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: qs.stringify({
                    key: "12321", // Replace with your actual key
                    parent: crudTableData.parent,
                    position: crudTableData.position,
                }),
            }
        );

        if (!response.ok) {
            throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        if (data.responsecode === 200) {
            setSelectedData(data.data);
        } else {
            console.error("Failed to fetch data:", data.message);
        }
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};



// input type selected then store validation list in a state

export const handleCrudTable = (e, setCrudTableData, setSelectedTableName) => {
    const { name, value } = e.target;
    console.log("table name ");
    console.log(value);

    setCrudTableData((prevData) => ({
        ...prevData,
        [name]: value,
    }));

    setSelectedTableName(value);
};

// export const handlePositionInputChange = (e, setCrudTableData, groups,setPositionData) => {
//     console.log("hello")
//     const { name, value } = e.target;
//     console.log(name, value)

//     // setCrudTableData((prevData) => ({
//     //     ...prevData,
//     //     [name]: value,
//     // }));

//     if (name === 'group') {
//         setCrudTableData((prevData) => ({
//             ...prevData,
//             group_id: value,
//             position: '', // Reset position when parent changes
//         }));

//         const postionData = []


//         groups.map((data) => {
//             if( data.parent == value){
//                 postionData.push(data)
//             }

//         })

//         setPositionData(postionData)


//     } else if (name === 'position') {
//         console.log("position")
//         setCrudTableData((prevData) => ({
//             ...prevData,
//             group_id: value,
//             position: value,
//         }));
//     }
// };




export const handleGroupChange = (e, setCrudTableData, groups, setPositionData,setSelectedSuperParent) => {

    const { name, value } = e.target;

    const postionData = []

    console.log("group id")

    console.log(value)

    groups.map((data) => {
        if (data.parent == value) {
            postionData.push(data)
        }
    })

    setPositionData(postionData)

    setSelectedSuperParent(value)

    setCrudTableData((prevData) => ({
        ...prevData,
        group_id: value,
        position: '', // Reset position when parent changes
    })
    );
}


export const handlePositionInputChange = (e, setCrudTableData, groups, setPositionData) => {

    const { name, value } = e.target;

    setCrudTableData((prevData) => ({
        ...prevData,
        group_id: value,
        position: value,
    }));

};


export const fetchGroupIdByChildId = (groups, group_id, selectedSuperParent) => {
    // Check if the group_id matches any group's id with parent == 0
    const parentGroup = groups.find((data) => data.id === group_id && data.parent === "0");

    // Return the group's id if found, otherwise return selectedSuperParent
    return parentGroup ? parentGroup.id : selectedSuperParent;
};



    // export const handlePositionInputChange = (e, setCrudTableData, groups, setPositionData) => {
    //     const { name, value } = e.target;


    //     if (name === 'group') {
    //         setCrudTableData((prevData) => ({
    //             ...prevData,
    //             group_id: value,
    //             position: '', // Reset position when parent changes
    //         }));

    //         const postionData = []


    //         groups.map((data) => {
    //             if (data.parent == value) {
    //                 postionData.push(data)
    //             }


    //         })

    //         setPositionData(postionData)


    //     } else if (name === 'position') {
    //         console.log("position")
    //         setCrudTableData((prevData) => ({
    //             ...prevData,
    //             group_id: value,
    //             position: value,
    //         }));
    //     }
    // };


    export const handleCheckboxChange = (e, setCrudTableData) => {
        const { id, checked } = e.target;
        console.log(id, checked)

        setCrudTableData((prevData) => ({
            ...prevData,
            [id]: checked ? 1 : 0, // Store as 1 or 0 for database compatibility
        }));
    };

    export const crudFieldCheckbox = (e, fieldId, setCrudFieldData) => {
        const { checked } = e.target;

        setCrudFieldData(prevData =>
            prevData.map(field =>
                field.id === fieldId ? { ...field, status: checked ? 1 : 0 } : field
            )
        );
    };

// export const crudFieldCheckbox = (e, fieldId, setCrudFieldData) => {
//     const { checked } = e.target;

//     setCrudFieldData(prevData =>
//         prevData.map(field =>
//             field.id === fieldId ? { ...field, isChecked: checked, status: checked ? 1 : 0 } : field
//         )
//     );
// };



