// src/utils/apiUtils.js
import Swal from 'sweetalert2';
import qs, { stringify } from "qs";
import { logDOM } from '@testing-library/react';
import axios from 'axios';



const keyvalue = "12321";
const BASE_URL = "https://admissioninnepal.in/api";
const userId = localStorage.getItem('token');



export const handleDeleteGroup = async (id, table_name) => {

  console.log("first")
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, cancel!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const data = qs.stringify({
          id,
          key: 12321,
          table: table_name
        });

        await axios.post(
          "https://admissioninnepal.in/api/delete-group",
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        Swal.fire({
          title: "Deleted!",
          text: "Item has been deleted.",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        console.error("Error deleting item:", error);
        Swal.fire({
          title: "Error",
          text: "Failed to delete item",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  });
};



export const fetchCrudAndCrudField = async (
  id,
  setCrudTableData,
  setCrudFieldData,
  setIsTableVisible,
  setTableFields,
  setCrudDataVisibility
) => {

  const newObj = {
    key: "12321",
    id: id
  };

  try {
    // Fetch crud and crud_field data
    const crudFieldResponse = await fetch(`https://admissioninnepal.in/Apis/Crud_field/crudnfield`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: qs.stringify(newObj)
    });

    if (!crudFieldResponse.ok) {
      throw new Error('Failed to fetch crud field data');
    }

    const crudFieldData = await crudFieldResponse.json();
    const crudData = crudFieldData.data['crud'];
    const crudField = crudFieldData.data['crud_field'];

    console.log("crudData", crudData);
    const tableName = crudData['table_name'];

    // Fetch table column data using the table name
    const tableColumnResponse = await fetch(`https://admissioninnepal.in/api/get-column/${tableName.replace("tbl_", "")}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: qs.stringify(newObj)
    });

    if (!tableColumnResponse.ok) {
      throw new Error('Failed to fetch table columns');
    }

    const tableColumnsData = await tableColumnResponse.json();

    console.log("tableColumnsData", tableColumnsData);
    setIsTableVisible(true);

    // Convert pattern and options to objects in crudField
    const processedCrudField = crudField.map(field => {
      return {
        ...field,
        pattern: field.pattern ? JSON.parse(field.pattern) : "",
        options: field.options ? JSON.parse(field.options) : ""
      };
    });

    // Append missing columns to crudField
    tableColumnsData.forEach(column => {
      const existsInCrudField = processedCrudField.some(field => field.field_name === column.COLUMN_NAME);
      if (!existsInCrudField) {
        const newField = {
          id: "",
          crud_id: column.id,
          field_name: column.COLUMN_NAME,
          field_label: "",
          field_type: "",
          attribute: "",
          is_required: 0,
          is_dependent: 0,
          dependency: "",
          default_value: "",
          is_dynamic: 0,
          dynamic_table: "",
          dynamic_field: "",
          dynamic_label: "",
          dynamic_condition: "",
          pattern: "",
          status: 0,
          is_visible: 0,
          col_size: "",
          position: "",
          options: ""
        };
        processedCrudField.push(newField);
      }
    });

    console.log("Updated crudFieldData", processedCrudField);

    // Remove id from crudData and set the rest in state
    const { id: crudId, ...filteredCrudData } = crudData;
    setCrudTableData(filteredCrudData);
    setCrudDataVisibility(true);

    console.log("table_column", crudFieldData.data['table_column']);
    setTableFields(crudFieldData.data['table_column']);
    setCrudFieldData(processedCrudField);

  } catch (err) {
    console.error("Error fetching data:", err);
  }
};


export const fetchCrudData = async (id, setCrudTableData) => {

  const newObj = {
    key: "12321",
    id: id
  }

  try {
    const response = await fetch(`${BASE_URL}/crud-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: qs.stringify(newObj)

    })

    console.log(response)

    if (response.ok) {
      const data = await response.json();
      console.log(" crud data")
      console.log(data.data[0])
      setCrudTableData(data.data[0])
    }

  } catch (err) {
    console.log(err)
  }
}


export const submitCrud = async (e, crudTableData) => {
  e.preventDefault();

  const newObj = {
    ...crudTableData,
    key: "12321",
  };

  try {
    const response = await fetch(
      "https://admissioninnepal.in/api/add-edit-crud",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify(newObj),
      }
    );

    if (response.ok) {
      const data = await response.json();
      const crudId = data.data.id;
      console.log(data)
      // setCrudFieldData(crudId);

    }
  } catch (err) {
    console.log(err)
  }
}

export const getCrudWithCrudFieldDataBySlug = async (slug, setCrudFieldData, setFormData, setCrudTableData) => {
  try {
    const response = await fetch(`${BASE_URL}/crud-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: 12321, slug: slug }), // Correctly encoded body
    });

    if (response.ok) {
      let crudData = await response.json();
      crudData = crudData.data[0]

      console.log("crud table data");
      console.log(crudData.id);

      const newForm = {
        key: 12321,
        crud_id: crudData.id,
      };

      try {
        const response = await fetch(`${BASE_URL}/crudfield-list`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: qs.stringify(newForm),
        });

        if (response.ok) {
          const data = await response.json();
          console.log("data.data")
          console.log(data.data)
          const formBuilderData = data.data;

          if (!formBuilderData || formBuilderData.length === 0) {
            console.warn("No form builder data returned from the API.");
            return;
          }

          // Initialize formData state based on fetched fields
          const initialFormData = formBuilderData.reduce((acc, field) => {

            if (field.field_type === 'custom_checkbox' || field.field_type === 'custom_select_multiple') {
              acc[field.field_name] = []; // Initialize checkboxes as an array
            }
            else if (field.field_type === 'current_user_id') {
              acc[field.field_name] = localStorage.getItem('token');

            } else {
              acc[field.field_name] = ""; // Initialize other fields as an empty string
            }

            // Handle fields with options
            if (field.options && ['custom_select', 'custom_option', 'custom_checkbox', 'custom_select_multiple'].includes(field.field_type)) {
              try {
                const options = JSON.parse(field.options);

                if (field.field_type === 'custom_select_multiple') {
                  const defaultOptions = options
                    .filter(option => option.defaultValue === true)
                    .map(option => option.value);
                  acc[field.field_name] = defaultOptions; // Use this array for preselected values
                }

                // Handle custom_select and custom_option by setting a single default value
                if (['custom_select', 'custom_option'].includes(field.field_type)) {
                  const defaultOption = options.find(option => option.defaultValue === true);
                  if (defaultOption) {
                    acc[field.field_name] = defaultOption.value;
                  }
                }

                // Handle custom_checkbox by appending default values to the array
                if (field.field_type === 'custom_checkbox') {
                  const defaultOptions = options
                    .filter(option => option.defaultValue === true)
                    .map(option => option.value);
                  acc[field.field_name] = defaultOptions;
                }

              } catch (error) {
                console.error(`Error parsing options for field ${field.field_name}:`, error);
              }
            }

            return acc;
          }, {});


          console.log("initialFormData")
          console.log(initialFormData)

          setCrudTableData(crudData)
          setCrudFieldData(formBuilderData);
          setFormData(initialFormData);
        }

      } catch (error) {
        console.error("Fetching sidebar data failed", error);
      }

    }

  } catch (error) {
    console.error("Fetching sidebar data failed", error);
  }

}

// fetch sidebar data

export const fetchSidebarData = async (setSideBarData) => {
  try {
    const response = await fetch("https://admissioninnepal.in/Apis/Dashboard", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: 12321 }), // Correctly encoded body
    });

    if (!response.ok) {
      console.error("Fetching sidebar data failed");
    }

    // Parse response as JSON
    const data = await response.json();

    // Update sidebar data state
    setSideBarData(data);
  } catch (error) {
    console.error("Fetching sidebar data failed", error);
  }
};



export const insertFormBuilderData = async (formData, table_name, crudFieldData) => {

  const newFormData = new FormData();

  crudFieldData.map((field) => {
    if (field.field_name === 'editor') {
      // Check if the field has a value, if yes, stringify it, otherwise set it to an empty string
      formData[field.field_name] = formData[field.field_name]
        ? JSON.stringify(formData[field.field_name])
        : "";
    }
  })




  // Create an array to hold the data
  const data = [];

  // Populate the data array with form data fields
  for (const [key, value] of Object.entries(formData)) {

    // Handle single file uploads
    if (value instanceof File) {

      // Append the single file to newFormData
      newFormData.append(key, value);

    }
    // Handle multiple file uploads
    else if (Array.isArray(value) && value.length > 1 && value[0] instanceof File) {
      console.log("multiple file");
      const filesArray = [];
      value.forEach((file, index) => {
        filesArray.push(file);
      });

      // After appending all files, append the final array to newFormData
      filesArray.forEach((file, index) => {
        newFormData.append(`${key}[${index}]`, file);
      });


    } else {
      // Append other fields to the newFormData
      newFormData.append(`data[${key}]`, value);
    }
  }

  newFormData.append("table_name", table_name);
  newFormData.append("key", "12321");



  console.log("newFormData");
  for (let pair of newFormData.entries()) {
    console.log(pair[0] + ', ' + pair[1]);
  }


  try {
    const response = await fetch(`${BASE_URL}/insert`, {
      method: "POST",
      body: newFormData, // No need to set Content-Type, fetch will handle it automatically

    });


    const data = await response.json();


    if (response.ok) {
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: data.message || 'Data submitted successfully!',
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: data.message || 'Failed to submit data!',
      });
    }
  } catch (error) {
    console.error("Failed to fetch CRUD table data:", error);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'An error occurred while submitting the data!',
    });
    throw error;
  }
}


// export const insertFormBuilderData = async (formData, table_name) => {
//   const newFormData = new FormData();

//   // Append the form data fields
//   for (const [key, value] of Object.entries(formData)) {
//     newFormData.append(key, value);
//   }

//   newFormData.append("table_name", table_name);
//   newFormData.append("key", "12321");


//   console.log("newFormData");
//   for (let pair of newFormData.entries()) {
//     console.log(pair[0]+ ', ' + pair[1]); 
//   }


//   try {
//     const response = await fetch(`${BASE_URL}/insert`, {
//       method: "POST",
//       body: newFormData, // No need to set Content-Type, fetch will handle it automatically
//     });

//     if (!response.ok) {
//       console.log(response)
//       throw new Error(`Network response was not ok: ${response.statusText}`);
//     }

//     const data = await response.json();
//     console.log("data");
//     console.log(data);

//   } catch (error) {
//     console.error("Failed to fetch crud table data:", error);
//     throw error;
//   }
// }



// FetchApi.js

export const fetchTableFieldsOnly = async (tableName) => {
  console.log("first")
  console.log(tableName)
  let data = []
  try {
    const response = await fetch(
      `https://admissioninnepal.in/api/get-column/${tableName.replace("tbl_", "")}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify({ key: 12321 }), // Use env variables for sensitive data
      }
    );

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    data = await response.json();

  }
  catch (err) {
    console.error("Failed to fetch table fields:", err);
  }
  return { data }
}

export const fetchTableFields = async (tableName) => {
  let data = [];
  let crudFieldData = [];
  let isTableVisible = false;

  try {
    const response = await fetch(
      `https://admissioninnepal.in/api/get-column/${tableName.replace("tbl_", "")}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify({ key: 12321 }), // Use env variables for sensitive data
      }
    );

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    data = await response.json();

    // console.log(data)
    isTableVisible = true;

    // Initialize CRUD field data with fetched table fields
    console.log("data1")
    console.log(data)

    crudFieldData = data.map((field) => ({
      id: field.id,
      crud_id: "",
      field_name: field.COLUMN_NAME,
      field_label: "",
      field_type: "",

      attribute: "",
      is_required: 0,
      is_dependent: 0,
      dependency: "",
      default_value: "",

      is_dynamic: 0,
      dynamic_table: "",
      dynamic_field: "",
      dynamic_label: "",
      dynamic_condition: "",

      pattern: "",
      status: 0,
      is_visible: 0,
      col_size: "",
      position: "",

      options: ""
    }));
  } catch (err) {
    console.error("Failed to fetch table fields:", err);
  } finally {
  }

  console.log(crudFieldData)

  return { crudFieldData, tableFields: data, isTableVisible };
};


// fetch column for dyamic select
export const fetchFieldsForDynamicSelect = async (tableName) => {
  let data = [];
  let crudFieldData = [];
  try {
    const response = await fetch(
      `https://admissioninnepal.in/api/get-column/${tableName.replace("tbl_", "")}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify({ key: 12321 }), // Use env variables for sensitive data
      }
    );

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    data = await response.json();


  } catch (err) {
    console.error("Failed to fetch table fields:", err);
  } finally {
  }

  return { crudFieldData, tableFields: data };
};


export const fetchtabledata = async (tableName, keyvalue) => {

  const newTableName = tableName.replace('tbl_', "");
  console.log("newTableName")
  console.log(newTableName)

  try {
    const response = await fetch(`https://admissioninnepal.in/Apis/crud/getdatanew/${newTableName}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue }),
    });


    if (response.ok) {

      const data = await response.json();
      return data; // Assuming the API response structure has a 'data' field    }
    }
  } catch (error) {
    console.error("Failed to fetch crud table data:", error);
    throw error;
  }
};

export async function getCrudFieldList(setCrudFieldData, setFormData, crud_id, itemData) {
  const newForm = {
    key: 12321,
    crud_id: crud_id,
  };

  try {
    const response = await fetch(`${BASE_URL}/crudfield-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify(newForm),
    });


    const data = await response.json();
    console.log("data.data")
    console.log(data.data)
    const formBuilderData = data.data;


    if (!formBuilderData || formBuilderData.length === 0) {
      console.warn("No form builder data returned from the API.");
      return;
    }

    // Initialize formData state based on fetched fields
    const initialFormData = formBuilderData.reduce((acc, field) => {

      if (field.field_type === 'custom_checkbox' || field.field_type === 'custom_select_multiple') {
        acc[field.field_name] = []; // Initialize checkboxes as an array
      }
      else if (field.field_type === 'current_user_id') {
        acc[field.field_name] = localStorage.getItem('token');

      }


      else {

        acc[field.field_name] = ""; // Initialize other fields as an empty string
      }

      // Handle fields with options
      if (field.options && ['custom_select', 'custom_option', 'custom_checkbox', 'custom_select_multiple'].includes(field.field_type)) {
        try {
          const options = JSON.parse(field.options);

          if (field.field_type === 'custom_select_multiple') {
            const defaultOptions = options
              .filter(option => option.defaultValue === true)
              .map(option => option.value);

            acc[field.field_name] = defaultOptions; // Use this array for preselected values
          }

          // Handle custom_select and custom_option by setting a single default value
          if (['custom_select', 'custom_option'].includes(field.field_type)) {
            const defaultOption = options.find(option => option.defaultValue === true);
            if (defaultOption) {
              acc[field.field_name] = defaultOption.value;
            }
          }

          // Handle custom_checkbox by appending default values to the array
          if (field.field_type === 'custom_checkbox') {
            const defaultOptions = options
              .filter(option => option.defaultValue === true)
              .map(option => option.value);
            acc[field.field_name] = defaultOptions;
          }


        } catch (error) {
          console.error(`Error parsing options for field ${field.field_name}:`, error);
        }
      }
      return acc;

    }, {});



    console.log("initialFormData")
    console.log(initialFormData)

    // put value in initialFormData using itemData


    if (itemData) {

      console.log("itemData")
      console.log(itemData)

      formBuilderData.map((field) => {
        // if input type == editor parse into json
        if (field.field_type === 'editor') {
          console.log(JSON.parse(itemData[field.field_name]))

          initialFormData[field.field_name] = itemData[field.field_name] ? JSON.parse(itemData[field.field_name]) : ""
        }
        else if (field.field_type === 'select_multiple') {
          console.log("heyyy")

          initialFormData[field.field_name] = itemData[field.field_name] ? itemData[field.field_name].split(',') : [];
          // console.log(arr)

        }
        else {
          initialFormData[field.field_name] = itemData[field.field_name] ? itemData[field.field_name] : ""
        }

      })
    }


    // convert multiple string value into array

    console.log("initialFormData1")
    console.log(initialFormData)


    setCrudFieldData(formBuilderData);
    // correct above object entries to key value of initialFormData data


    // update into setFromData
    setFormData(initialFormData);

  } catch (error) {
    console.error("Fetching form data failed data failed", error);
  }
}



export const fetchCrudTableData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/crud-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue }),
    });


    if (!response.ok) {
      throw new Error("Network response was not ok: ");
    }

    const data = await response.json();

    return data.data; // Assuming the API response structure has a 'data' field
  } catch (error) {
    console.error("Failed to fetch crud table data:", error);
    throw error;
  }
};


export const fetchRegionData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/region-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data.data; // Assuming the API response structure has a 'data' field
  } catch (error) {
    console.error("Failed to fetch region data:", error);
    throw error;
  }
};

export const fetchCountryData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/country-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data.data; // Assuming the API response structure has a 'data' field
  } catch (error) {
    console.error("Failed to fetch country data:", error);
    throw error;
  }
};

export const fetchStateData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/state-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data.data; // Assuming the API response structure has a 'data' field
  } catch (error) {
    console.error("Failed to fetch state data:", error);
    throw error;
  }
};

export const fetchCityData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/city-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data.data; // Assuming the API response structure has a 'data' field
  } catch (error) {
    console.error("Failed to fetch city data:", error);
    throw error;
  }
};

export const fetchlocalityData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/locality-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data.data; // Assuming the API response structure has a 'data' field
  } catch (error) {
    console.error("Failed to fetch locality data:", error);
    throw error;
  }
};

export const fetchStreamData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/stream-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data.data; // Assuming the API response structure has a 'data' field
  } catch (error) {
    console.error("Failed to fetch Stream data:", error);
    throw error;
  }
};

export const fetchDegreeData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/degree-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data.data; // Assuming the API response structure has a 'data' field
  } catch (error) {
    console.error("Failed to fetch degree data:", error);
    throw error;
  }
};

export const fetchCourseData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/course-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data.data; // Assuming the API response structure has a 'data' field
  } catch (error) {
    console.error("Failed to fetch degree data:", error);
    throw error;
  }
};

export const fetchSpecializationData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/specialization-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify({ key: keyvalue }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data.data; // Assuming the API response structure has a 'data' field
  } catch (error) {
    console.error("Failed to fetch degree data:", error);
    throw error;
  }
};


export const fetchData = async (apiEndpoint, requestBody = {}, method = "POST") => {
  console.log(apiEndpoint)

  console.log(`${BASE_URL}/${apiEndpoint}`)

  try {
    const response = await fetch(`${BASE_URL}/${apiEndpoint}`, {
      method: method,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: qs.stringify({ key: keyvalue }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    console.log("Fetched Data: for insert", data.data);
    return data.data;
  } catch (error) {
    console.error("Fetching data failed", error);
    throw error;
  }
};



export const updateFormBuilderData = async (formData, table_name, id) => {
  const newFormData = new FormData();

  // Log to check if id is correctly passed
  console.log("ID being passed to updateFormBuilderData:", id);

  // Populate the FormData with form data fields
  for (const [key, value] of Object.entries(formData)) {

    // Handle single file uploads
    if (value instanceof File) {
      console.log("single file");
      newFormData.append(key, value);
    } else if (
      Array.isArray(value) &&
      value.length > 1 &&
      value[0] instanceof File
    ) {
      console.log("multiple file");
      value.forEach((file, index) => {
        newFormData.append(`${key}[${index}]`, file);
      });
    } else {
      console.log("text data");
      newFormData.append(`data[${key}]`, value);
    }
  }

  // Append additional fields like table name and key
  newFormData.append("table_name", table_name);
  newFormData.append("key", "12321");

  // Append the id for the update
  newFormData.append("data[id]", id);

  console.log("newFormData entries:");
  for (let pair of newFormData.entries()) {
    console.log(pair[0] + ", " + pair[1]);
  }

  try {
    const response = await fetch(`${BASE_URL}/insert`, {
      method: "POST", // Use POST for updates
      body: newFormData, // No need to set Content-Type, fetch will handle it automatically
    });

    const data = await response.json();
    console.log("response data:");
    console.log(data);

    if (response.ok) {
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: data.message || "Data updated successfully!",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: data.message || "Failed to update data!",
      });
    }
  } catch (error) {
    console.error("Failed to fetch CRUD table data:", error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "An error occurred while updating the data!",
    });
    throw error;
  }
};


