import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import Swal from "sweetalert2";

const INACTIVITY_TIME_LIMIT = 24 * 60 * 1000;
const CHECK_INTERVAL = 1000; // Check every 1 second

const Login = () => {
  const keyvalue = "12321";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://admissioninnepal.in/api/login",
        qs.stringify({
          email,
          password,
          key: keyvalue,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.data.respocecode === 200) {
        const userData = response.data.data;
        const tokenExpiryTime = 60 * 60 * 1000;

        localStorage.setItem("token", userData.id);
        localStorage.setItem("tokenExpiry", Date.now() + tokenExpiryTime);
        localStorage.setItem("lastActivity", Date.now());

        if (userData.user_type === "1") {
          Swal.fire({
            icon: "success",
            title: "Login Successful",
            text: "Welcome Admin!",
          }).then(() => {
            navigate("/dashboardpage");
          });
        } else if (userData.user_type === "2") {
          Swal.fire({
            icon: "success",
            title: "Login Successful",
            text: "Welcome User!",
          }).then(() => {
            navigate("/userdashboard");
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "Login Successful",
            text: "Unrecognized user type.",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text: response.data.message || "Invalid email or password",
        });
      }
    } catch (error) {
      console.error("Login failed", error);
      Swal.fire({
        icon: "error",
        title: "Login Failed",
        text: "An error occurred during login. Please try again later.",
      });
    }
  };

  useEffect(() => {
    const events = ["click", "mousemove", "keypress"];
    const resetTimer = () => {
      localStorage.setItem("lastActivity", Date.now());
    };

    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, []);

  useEffect(() => {
    const checkInactivity = () => {
      const lastActivity = parseInt(localStorage.getItem("lastActivity"), 10);
      const tokenExpiry = parseInt(localStorage.getItem("tokenExpiry"), 10);

      if (
        Date.now() - lastActivity > INACTIVITY_TIME_LIMIT ||
        Date.now() > tokenExpiry
      ) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiry");
        localStorage.removeItem("lastActivity");
        navigate("/"); // Redirect to login page
      }
    };

    const interval = setInterval(checkInactivity, CHECK_INTERVAL);

    return () => clearInterval(interval);
  }, [navigate]);

  return (
    <div className="d-flex flex-column flex-root" id="kt_app_root">
      {/*begin::Authentication - Sign-in */}
      <div className="d-flex flex-column flex-lg-row flex-column-fluid">
        {/*begin::Body*/}
        <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
          {/*begin::Form*/}
          <div className="d-flex flex-center flex-column flex-lg-row-fluid">
            {/*begin::Wrapper*/}
            <div className="w-lg-500px p-10">
              {/*begin::Form*/}
              <form
                className="form w-100"
                noValidate="noValidate"
                id="kt_sign_in_form"
                onSubmit={handleSubmit}
              >
                <div className="text-center mb-11">
                  <h1 className="text-gray-900 fw-bolder mb-3">Sign In</h1>
                  {/* <div className="text-gray-500 fw-semibold fs-6">
                    Your Social Campaigns
                  </div> */}
                </div>
                {/* <div className="row g-3 mb-9">
                  <div className="col-md-6">
                    <a
                      href="/"
                      className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
                    >
                      <img
                        alt="Logo"
                        src="assets/media/svg/brand-logos/google-icon.svg"
                        className="h-15px me-3"
                      />
                      Sign in with Google
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a
                      href="/"
                      className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
                    >
                      <img
                        alt="Logo"
                        src="assets/media/svg/brand-logos/apple-black.svg"
                        className="theme-light-show h-15px me-3"
                      />
                      <img
                        alt="Logo"
                        src="assets/media/svg/brand-logos/apple-black-dark.svg"
                        className="theme-dark-show h-15px me-3"
                      />
                      Sign in with Apple
                    </a>
                  </div>
                </div> */}
                {/* <div className="separator separator-content my-14">
                  <span className="w-125px text-gray-500 fw-semibold fs-7">
                    Or with email
                  </span>
                </div> */}
                <div className="fv-row mb-8 ">
                  <input
                    type="email"
                    placeholder="Email"
                    className="form-control bg-transparent"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="fv-row mb-3 ">
                  <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    className="form-control bg-transparent"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                  <div></div>
                  <a
                    href="authentication/layouts/corporate/reset-password.html"
                    className="link-primary"
                  >
                    Forgot Password ?
                  </a>
                </div>
                <div className="d-grid mb-10">
                  <button
                    type="submit"
                    id="kt_sign_in_submit"
                    className="btn btn-primary"
                  >
                    <span className="indicator-label">Sign In</span>
                    <span className="indicator-progress">
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                </div>
                {/* <div className="text-gray-500 text-center fw-semibold fs-6">
                  Not a Member yet?
                  <a
                    href="authentication/layouts/corporate/sign-up.html"
                    className="link-primary"
                  >
                    Sign up
                  </a>
                </div> */}
              </form>
              {/*end::Form*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Form*/}
          {/*begin::Footer*/}
          <div className="w-lg-500px d-flex flex-stack px-10 mx-auto">
            {/*begin::Languages*/}
            <div className="me-10">
              {/*begin::Toggle*/}
              <button
                className="btn btn-flex btn-link btn-color-gray-700 btn-active-color-primary rotate fs-base"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-start"
                data-kt-menu-offset="0px, 0px"
              >
                <img
                  data-kt-element="current-lang-flag"
                  className="w-20px h-20px rounded me-3"
                  src="assets/media/flags/united-states.svg"
                  alt=""
                />
                <span data-kt-element="current-lang-name" className="me-1">
                  English
                </span>
                <span className="d-flex flex-center rotate-180">
                  <i className="ki-duotone ki-down fs-5 text-muted m-0" />
                </span>
              </button>
              {/*end::Toggle*/}
              {/*begin::Menu*/}
              <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7"
                data-kt-menu="true"
                id="kt_auth_lang_menu"
              >
                {/*begin::Menu item*/}
                <div className="menu-item px-3">
                  <a
                    href=""
                    className="menu-link d-flex px-5"
                    data-kt-lang="English"
                  >
                    <span className="symbol symbol-20px me-4">
                      <img
                        data-kt-element="lang-flag"
                        className="rounded-1"
                        src="assets/media/flags/united-states.svg"
                        alt=""
                      />
                    </span>
                    <span data-kt-element="lang-name">English</span>
                  </a>
                </div>
                {/*end::Menu item*/}
                {/*begin::Menu item*/}
                <div className="menu-item px-3">
                  <a
                    href=""
                    className="menu-link d-flex px-5"
                    data-kt-lang="Spanish"
                  >
                    <span className="symbol symbol-20px me-4">
                      <img
                        data-kt-element="lang-flag"
                        className="rounded-1"
                        src="assets/media/flags/spain.svg"
                        alt=""
                      />
                    </span>
                    <span data-kt-element="lang-name">Spanish</span>
                  </a>
                </div>
                {/*end::Menu item*/}
                {/*begin::Menu item*/}
                <div className="menu-item px-3">
                  <a
                    href=""
                    className="menu-link d-flex px-5"
                    data-kt-lang="German"
                  >
                    <span className="symbol symbol-20px me-4">
                      <img
                        data-kt-element="lang-flag"
                        className="rounded-1"
                        src="assets/media/flags/germany.svg"
                        alt=""
                      />
                    </span>
                    <span data-kt-element="lang-name">German</span>
                  </a>
                </div>
                {/*end::Menu item*/}
                {/*begin::Menu item*/}
                <div className="menu-item px-3">
                  <a
                    href=""
                    className="menu-link d-flex px-5"
                    data-kt-lang="Japanese"
                  >
                    <span className="symbol symbol-20px me-4">
                      <img
                        data-kt-element="lang-flag"
                        className="rounded-1"
                        src="assets/media/flags/japan.svg"
                        alt=""
                      />
                    </span>
                    <span data-kt-element="lang-name">Japanese</span>
                  </a>
                </div>
                {/*end::Menu item*/}
                {/*begin::Menu item*/}
                <div className="menu-item px-3">
                  <a
                    href=""
                    className="menu-link d-flex px-5"
                    data-kt-lang="French"
                  >
                    <span className="symbol symbol-20px me-4">
                      <img
                        data-kt-element="lang-flag"
                        className="rounded-1"
                        src="assets/media/flags/france.svg"
                        alt=""
                      />
                    </span>
                    <span data-kt-element="lang-name">French</span>
                  </a>
                </div>
                {/*end::Menu item*/}
              </div>
              {/*end::Menu*/}
            </div>
            {/*end::Languages*/}
            {/*begin::Links*/}
            <div className="d-flex fw-semibold text-primary fs-base gap-5">
              <a href="pages/team.html" target="_blank">
                Terms
              </a>
              <a href="pages/pricing/column.html" target="_blank">
                Plans
              </a>
              <a href="pages/contact.html" target="_blank">
                Contact Us
              </a>
            </div>
            {/*end::Links*/}
          </div>
          {/*end::Footer*/}
        </div>
        {/*end::Body*/}
        {/*begin::Aside*/}
        <div
          className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
          style={{ backgroundImage: "url(assets/media/misc/auth-bg.png)" }}
        >
          {/*begin::Content*/}
          <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
            {/*begin::Logo*/}
            <a href="index.html" className="mb-0 mb-lg-12">
              <img
                alt="Logo"
                src="assets/media/logos/custom-1.png"
                className="h-60px h-lg-75px"
              />
            </a>
            {/*end::Logo*/}
            {/*begin::Image*/}
            <img
              className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20"
              src="assets/media/misc/auth-screens.png"
              alt=""
            />
            {/*end::Image*/}
            {/*begin::Title*/}
            <h1 className="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">
              Fast, Efficient and Productive
            </h1>
            {/*end::Title*/}
            {/*begin::Text*/}
            <div className="d-none d-lg-block text-white fs-base text-center">
              In this kind of post,
              <a href="" className="opacity-75-hover text-warning fw-bold me-1">
                the blogger
              </a>
              introduces a person they’ve interviewed
              <br />
              and provides some background information about
              <a href="" className="opacity-75-hover text-warning fw-bold me-1">
                the interviewee
              </a>
              and their
              <br />
              work following this is a transcript of the interview.
            </div>
            {/*end::Text*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Aside*/}
      </div>
      {/*end::Authentication - Sign-in*/}
    </div>
  );
};

export default Login;
