
import React, { useState, useEffect } from 'react';

// Simulating an API response
const fetchCheckboxData = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        table: 'hobbies',
        valueField: 'hobby_id',
        labelField: 'hobby_name',
        data: [
          { hobby_id: 1, hobby_name: 'Reading' },
          { hobby_id: 2, hobby_name: 'Gardening' },
          { hobby_id: 3, hobby_name: 'Cooking' },
        ],
      });
    }, 1000);
  });
};




const DynamicCheckbox = () => {
  const [checkboxData, setCheckboxData] = useState(null);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    fetchCheckboxData().then((data) => setCheckboxData(data));
  }, []);

  const handleCheckboxChange = (value) => {
    setSelectedValues((prevSelectedValues) => 
      prevSelectedValues.includes(value)
        ? prevSelectedValues.filter((v) => v !== value)
        : [...prevSelectedValues, value]
    );
  };

  if (!checkboxData) {
    return <div>Loading...</div>;
  }

  const { valueField, labelField, data } = checkboxData;

  return (
    <div>
      {data.map((item) => (
        <label key={item[valueField]}>
          <input
            type="checkbox"
            value={item[valueField]}
            onChange={() => handleCheckboxChange(item[valueField])}
            checked={selectedValues.includes(item[valueField])}
          />
          {item[labelField]}
        </label>
      ))}
    </div>
  );
};

export default DynamicCheckbox;
