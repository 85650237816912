import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchtabledata, fetchTableData } from "../../customhook/FetchApi";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import axios from "axios";
import qs from "qs";
import Swal from "sweetalert2";

import ReactPaginate from "react-paginate";

function DisplayCrudTableData() {
  const location = useLocation();
  const { crudTable } = location.state || {};

  const [TableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5; // Number of items per page
  const [searchTerm, setSearchTerm] = useState("");

  const [visibleFieldColumn, setVisibleFieldColumn] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    console.log("selected");
    console.log(data.selected);
  };

  const filteredData = TableData.filter(
    (item) =>
      (item.region_name?.toLowerCase() || "").includes(
        searchTerm.toLowerCase()
      ) || (item.title?.toLowerCase() || "").includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0); // Reset to first page on search
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  function insertNewData() {
    navigate("/form", {
      state: { crud_id: crudTable.id, table_name: crudTable.table_name },
    });
  }

  function editData(item) {

    navigate(`/form`, {
      state: {
        crud_id: crudTable.id,
        table_name: crudTable.table_name,
        itemData: item, // Pass the data to be edited

      },
    });

    // navigate(`/edit-form/${item.id}`, {
    //   state: {
    //     crud_id: crudTable.id,
    //     table_name: crudTable.table_name,
    //     itemData: item, // Pass the data to be edited

    //   },
    // });
  }
  // useEffect(() => {
  //   const getTableData = async () => {
  //     const keyvalue = "12321"; // Define the key value or fetch it as needed
  //     const tableName = crudTable.table_name.replace("tbl_", "");
  //     console.log(tableName);

  //     try {
  //       const table = await fetchtabledata(tableName, keyvalue);

  //       // Extract visiblefields and store in visibleFieldColumn
  //       const visibleFieldColumn = table.visiblefields;
  //       delete table.visiblefields;
  //       setVisibleFieldColumn(visibleFieldColumn);

  //       // Validate if table is an array (excluding visiblefields)
  //       const tableArray = Object.values(table).filter(
  //         (item) => typeof item === "object"
  //       );

  //       if (Array.isArray(tableArray)) {
  //         setTableData(tableArray);
  //       } else {
  //         throw new Error("Fetched data is not an array.");
  //       }
  //     } catch (error) {
  //       setError(error);
  //       console.error("Error fetching table data:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   getTableData();
  // }, []);

  // const deleteData = async (id) => {
  //   console.log("Deleting item with ID:", id);
  //   console.log("Table name:", crudTable.table_name); // Log the table name

  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes, delete it!",
  //     cancelButtonText: "No, cancel!",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       try {
  //         const data = qs.stringify({
  //           id,
  //           table: crudTable.table_name, // Pass table name along with ID
  //           key: 12321,
  //         });

  //         await axios.post(
  //           "https://admissioninnepal.in/api/delete-group",
  //           data,
  //           {
  //             headers: {
  //               "Content-Type": "application/x-www-form-urlencoded",
  //             },
  //           }
  //         );

  //         Swal.fire({
  //           title: "Deleted!",
  //           text: "Item has been deleted.",
  //           icon: "success",
  //           confirmButtonText: "OK",
  //         });
  //       } catch (error) {
  //         console.error("Error deleting item:", error);
  //         Swal.fire({
  //           title: "Error",
  //           text: "Failed to delete item",
  //           icon: "error",
  //           confirmButtonText: "OK",
  //         });
  //       }
  //     }
  //   });
  // };


  // extract visiblefields from table and store in visibleFieldColumn and remvoe visiblefields from table.

  const getTableData = async () => {
    const keyvalue = "12321"; // Define the key value or fetch it as needed
    const tableName = crudTable.table_name.replace("tbl_", "");

    try {
      const table = await fetchtabledata(tableName, keyvalue);

      // Extract visiblefields and store in visibleFieldColumn
      const visibleFieldColumn = table.visiblefields;
      delete table.visiblefields;
      setVisibleFieldColumn(visibleFieldColumn);

      
      // Validate if table is an array (excluding visiblefields)
      const tableArray = Object.values(table).filter(
        (item) => typeof item === "object"
      );

      console.log("tableArray")
      console.log(tableArray)

      if (Array.isArray(tableArray)) {
        setTableData(tableArray);
      } else {
        throw new Error("Fetched data is not an array.");
      }

    } catch (error) {
      setError(error);
      console.error("Error fetching table data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Use useEffect to call the async function
  useEffect(() => {
    getTableData();
  }, [crudTable]); // Include crudTable as a dependency if it might change


  const deleteData = async (item) => {
    const id = item.id; // Extract just the 'id' value from the item object

    console.log("Deleting item with ID:", id);
    console.log("Table name:", crudTable.table_name);
    getTableData();

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const data = qs.stringify({
            id: id, // Now passing only the ID value
            table: crudTable.table_name,
            key: 12321,
          });

          await axios.post(
            "https://admissioninnepal.in/api/delete-group",
            data,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );

          Swal.fire({
            title: "Deleted!",
            text: "Item has been deleted.",
            icon: "success",
            confirmButtonText: "OK",
          });

          // Refresh the data

        } catch (error) {
          console.error("Error deleting item:", error);
          Swal.fire({
            title: "Error",
            text: "Failed to delete item",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };


  return (
    <>
      <Header />
      <Sidebar />

      <div className="app-main flex-column flex-row-fluid " id="kt_app_main">
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
            <div
              id="kt_app_toolbar_container"
              className="app-container container-xxl d-flex flex-stack"
            >
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mt-5">
                <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                  List of Data inside table
                </h1>

                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                  <li className="breadcrumb-item text-muted">
                    <a
                      href="index.html"
                      className="text-muted text-hover-primary"
                    >
                      Home
                    </a>
                  </li>

                  <li className="breadcrumb-item">
                    <span className="bullet bg-gray-500 w-5px h-2px" />
                  </li>

                  <li className="breadcrumb-item text-muted">List of Data</li>
                </ul>
              </div>
            </div>
          </div>

          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-xxl"
            >
              <div className="card">
                <div className="card-header border-0 pt-6">
                  <div className="card-title">
                    {/*begin::Search*/}
                    <div className="d-flex align-items-center position-relative my-1">
                      <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                        <span className="path1" />
                        <span className="path2" />
                      </i>
                      <input
                        type="text"
                        data-kt-user-table-filter="search"
                        className="form-control form-control-solid w-250px ps-13"
                        placeholder="Search user"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>

                  <div className="card-toolbar">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-user-table-toolbar="base"
                    >
                      <button
                        type="button"
                        className="btn btn-light-primary me-3"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_export_users"
                      >
                        <i className="ki-duotone ki-exit-up fs-2">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                        Export
                      </button>

                      {crudTable.add_field == 1 && (
                        <button
                          onClick={insertNewData}
                          className="btn btn-primary"
                        >
                          Insert Data
                        </button>
                      )}

                      {/*end::Add user*/}
                    </div>

                    <div
                      className="d-flex justify-content-end align-items-center d-none"
                      data-kt-user-table-toolbar="selected"
                    >
                      <div className="fw-bold me-5">
                        <span
                          className="me-2"
                          data-kt-user-table-select="selected_count"
                        />
                        Selected
                      </div>
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-kt-user-table-select="delete_selected"
                      >
                        Delete Selected
                      </button>
                    </div>

                    <div
                      className="modal fade"
                      id="kt_modal_export_users"
                      tabIndex={-1}
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered mw-650px">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h2 className="fw-bold">Export Users</h2>

                            <div
                              className="btn btn-icon btn-sm btn-active-icon-primary"
                              data-kt-users-modal-action="close"
                            >
                              <i className="ki-duotone ki-cross fs-1">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            </div>
                          </div>

                          <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                            {/*begin::Form*/}
                            <form
                              id="kt_modal_export_users_form"
                              className="form"
                              action="#"
                            >
                              <div className="fv-row mb-10">
                                {/*begin::Label*/}
                                <label className="required fs-6 fw-semibold form-label mb-2">
                                  Select Export Format:
                                </label>

                                <select
                                  name="format"
                                  data-control="select2"
                                  data-placeholder="Select a format"
                                  data-hide-search="true"
                                  className="form-select form-select-solid fw-bold"
                                >
                                  <option />
                                  <option value="excel">Excel</option>
                                  <option value="pdf">PDF</option>
                                  <option value="cvs">CVS</option>
                                  <option value="zip">ZIP</option>
                                </select>
                                {/*end::Input*/}
                              </div>

                              <div className="text-center">
                                <button
                                  type="reset"
                                  className="btn btn-light me-3"
                                  data-kt-users-modal-action="cancel"
                                >
                                  Discard
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  data-kt-users-modal-action="submit"
                                >
                                  <span className="indicator-label">
                                    Submit
                                  </span>
                                  <span className="indicator-progress">
                                    Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                  </span>
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="modal fade"
                      id="kt_modal_add_user"
                      tabIndex={-1}
                      aria-hidden="true"
                    ></div>
                  </div>
                </div>

                <div className="card-body py-4">
                  <table
                    className="table align-middle table-row-dashed fs-6 gy-5"
                    id="kt_table_users"
                  >
                    <thead>
                      <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                        {visibleFieldColumn.map((field) => (
                          <td key={field}>{field}</td>
                        ))}
                      </tr>
                    </thead>

                    <tbody className="text-gray-600 fw-semibold">
                      {currentItems.length > 0 ? (
                        currentItems.map((data, index) => (
                          <tr key={index}>
                            {visibleFieldColumn.map((field) => (
                              <td key={field}>{data[field]}</td>
                            ))}
                            <td>
                              <div>
                                {/* Edit button */}
                                {crudTable.edit_field == 1 && (
                                  <button
                                    onClick={() => editData(data)}
                                    className="btn btn-icon btn-success"
                                  >
                                    <i className="fas fa-pencil-alt"></i>
                                  </button>
                                )}
                                {/* Delete button */}
                                {crudTable.delete_field == 1 && (
                                  <button
                                    className="btn btn-icon btn-danger mx-lg-3 mt-3 mt-lg-0"
                                    onClick={() => deleteData(data)}
                                  >
                                    <i className="fas fa-trash"></i>
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4" className="text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>

                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-stack flex-wrap pt-10">
          <div className="fs-6 fw-semibold text-gray-700">
            Showing {indexOfFirstItem + 1} to{" "}
            {Math.min(indexOfLastItem, filteredData.length)} of{" "}
            {filteredData.length} entries
          </div>
          <ReactPaginate
            pageCount={Math.ceil(filteredData.length / itemsPerPage)}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item previous"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item next"}
            nextLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>


    </>
  );
}

export default DisplayCrudTableData;
